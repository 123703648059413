<template>
    <div
        v-clickoutside="handleClose"
        class="el-select"
        :class="[selectSize ? 'el-select--' + selectSize : '']"
        @click.stop="toggleMenu"
    >
        <div
            v-if="multiple"
            ref="tags"
            class="el-select__tags"
            :style="{'max-width': inputWidth - 32 + 'px', width: '100%'}"
        >
            <span v-if="collapseTags && selected.length">
                <el-tag
                    :closable="!selectDisabled"
                    :size="collapseTagSize"
                    :hit="selected[0].hitState"
                    type="info"
                    disable-transitions
                    @close="deleteTag($event, selected[0])"
                >
                    <span class="el-select__tags-text">{{ selected[0].currentLabel }}</span>
                </el-tag>
                <el-tag
                    v-if="selected.length > 1"
                    :closable="false"
                    :size="collapseTagSize"
                    type="info"
                    disable-transitions
                >
                    <span class="el-select__tags-text">+ {{ selected.length - 1 }}</span>
                </el-tag>
            </span>
            <transition-group v-if="!collapseTags" @after-leave="resetInputHeight">
                <el-tag
                    v-for="item in selected"
                    :key="getValueKey(item)"
                    :closable="!selectDisabled"
                    :size="collapseTagSize"
                    :hit="item.hitState"
                    type="info"
                    disable-transitions
                    @close="deleteTag($event, item)"
                >
                    <span class="el-select__tags-text">{{ item.currentLabel }}</span>
                </el-tag>
            </transition-group>
            <input
                v-if="filterable"
                ref="input"
                v-model="query"
                type="text"
                class="el-select__input"
                :class="[selectSize ? `is-${ selectSize }` : '']"
                :disabled="selectDisabled"
                :autocomplete="autoComplete || autocomplete"
                :style="{'flex-grow': '1', width: inputLength / (inputWidth - 32) + '%', 'max-width': inputWidth - 42 + 'px'}"
                @focus="handleFocus"
                @blur="softFocus = false"
                @keyup="managePlaceholder"
                @keydown="resetInputState"
                @keydown.down.prevent="navigateOptions('next')"
                @keydown.up.prevent="navigateOptions('prev')"
                @keydown.enter.prevent="selectOption"
                @keydown.esc.stop.prevent="visible = false"
                @keydown.delete="deletePrevTag"
                @keydown.tab="visible = false"
                @compositionstart="handleComposition"
                @compositionupdate="handleComposition"
                @compositionend="handleComposition"
                @input="debouncedQueryChange"
            >
        </div>
        <el-input
            :id="id"
            ref="reference"
            v-model="selectedLabel"
            type="text"
            :placeholder="currentPlaceholder"
            :name="name"
            :autocomplete="autoComplete || autocomplete"
            :size="selectSize"
            :disabled="selectDisabled"
            :readonly="readonly"
            :validate-event="false"
            :class="{'is-focus': visible}"
            @focus="handleFocus"
            @blur="handleBlur"
            @keyup.native="debouncedOnInputChange"
            @keydown.native.down.stop.prevent="navigateOptions('next')"
            @keydown.native.up.stop.prevent="navigateOptions('prev')"
            @keydown.native.esc.stop.prevent="visible = false"
            @keydown.native.tab="visible = false"
            @paste.native="debouncedOnInputChange"
            @mouseenter.native="inputHovering = true"
            @mouseleave.native="inputHovering = false"
        >
            <template v-if="$slots.prefix" slot="prefix">
                <slot name="prefix"></slot>
            </template>
            <template slot="suffix">
                <i v-show="!showClose" :class="['el-select__caret', 'el-input__icon', 'el-icon-' + iconClass]"></i>
                <i
                    v-if="showClose" class="el-select__caret el-input__icon el-icon-circle-close"
                    @click="handleClearClick"
                ></i>
            </template>
        </el-input>
        <transition
            name="el-zoom-in-top"
            @before-enter="handleMenuEnter"
            @after-leave="doDestroy"
        >
            <el-select-menu
                v-show="visible && emptyText !== false"
                ref="popper"
                :append-to-body="popperAppendToBody"
            >
                <el-scrollbar
                    v-show="options.length > 0"
                    ref="scrollbar"
                    tag="ul"
                    wrap-class="el-select-dropdown__wrap"
                    view-class="el-select-dropdown__list"
                    :class="{'is-empty': !allowCreate && query && filteredOptionsCount === 0}"
                >
                    <el-option
                        v-if="showNewOption"
                        :value="query"
                        created
                    />
                    <slot></slot>
                </el-scrollbar>
                <template v-if="emptyText && (!allowCreate || loading || (allowCreate && options.length === 0 ))">
                    <slot v-if="$slots.empty" name="empty"></slot>
                    <template v-else>
                        <p class="el-select-dropdown__empty" :class="{'el-select-dropdown__item': loading}">
                            <i v-if="loading" class="el-icon-loading"></i>
                            {{ emptyText }}
                        </p>
                    </template>
                </template>
            </el-select-menu>
        </transition>
    </div>
</template>
<script>
// 重写element-ui select组件部分方法、、、
import {Select} from 'element-ui';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import { getValueByPath } from 'element-ui/src/utils/util';
export default {
    extends: Select,
    name: 'MyCustomSelect',
    directives: { Clickoutside },
    data() {
        return {
            cacheQuery: null,
            firstSelected: {
                hitState: false,
                currentLabel: '',
                currentValue: '',
                value: ''
            }
        };
    },
    computed: {
        iconClass() {
            return this.visible ? 'arrow-up is-reverse' : 'arrow-up';
            // return this.remote && this.filterable ? '' : (this.visible ? 'arrow-up is-reverse' : 'arrow-up');
        },
        emptyText() {
            if (this.loading) {
                return this.loadingText || this.t('el.select.loading');
            } else {
                if (this.remote && this.query === '' && this.options.length === 0) {
                    return this.noDataText || this.t('el.select.noData');
                }
                if (this.filterable && this.query && this.options.length > 0 && this.filteredOptionsCount === 0) {
                    return this.noMatchText || this.t('el.select.noMatch');
                }
                if (this.options.length === 0) {
                    return this.noDataText || this.t('el.select.noData');
                }
            }
            return null;
        }
    },
    watch: {
        query(val) {
            this.$emit('get-query', val);
        },
        visible(val) {
            if (!val) {
                this.broadcast('ElSelectDropdown', 'destroyPopper');
                if (this.$refs.input) {
                    this.$refs.input.blur();
                }
                this.query = '';
                this.previousQuery = null;
                this.selectedLabel = '';
                this.inputLength = 20;
                this.menuVisibleOnFocus = false;
                this.resetHoverIndex();
                this.$nextTick(() => {
                    if (this.$refs.input &&
                        this.$refs.input.value === '' &&
                        this.selected.length === 0) {
                        this.currentPlaceholder = this.cachedPlaceHolder;
                    }
                });
                if (!this.multiple) {
                    // console.log(this.selected, this.selectedLabel, 1);
                    if (this.selected) {
                        if (this.filterable && this.allowCreate &&
                            this.createdSelected && this.createdLabel) {
                            this.selectedLabel = this.createdLabel;
                        } else {
                            const label = this.getOption(this.value)?.currentLabel || '';
                            this.selectedLabel = label;
                        }
                        if (this.filterable) this.query = this.selectedLabel;
                    }
                    // console.log(this.selected, this.selectedLabel, 2);
                    if (this.filterable) {
                        this.currentPlaceholder = this.cachedPlaceHolder;
                    }
                } else {
                    this.setSelected();
                }
            } else {
                this.broadcast('ElSelectDropdown', 'updatePopper');
                if (this.filterable) {
                    this.query = this.remote ? '' : this.selectedLabel;
                    this.handleQueryChange(this.query);
                    if (this.multiple) {
                        this.$refs.input.focus();
                    } else {
                        if (!this.remote) {
                            this.broadcast('ElOption', 'queryChange', '');
                            this.broadcast('ElOptionGroup', 'queryChange');
                        }

                        if (this.selectedLabel) {
                            this.currentPlaceholder = this.selectedLabel;
                            this.selectedLabel = '';
                        }
                    }
                }
            }
            this.$emit('visible-change', {val, isCustom: true});
        }
    },
    methods: {
        selectOption() {
            if (!this.visible) {
                this.toggleMenu();
            } else {
                if (this.options[this.hoverIndex]) {
                    // 避免enter选中
                    // this.handleOptionSelect(this.options[this.hoverIndex]);
                }
            }
        },
        deleteTag(event, tag) {
            let index = this.selected.indexOf(tag);
            if (index > -1 && !this.selectDisabled) {
                const value = this.value.slice();
                value.splice(index, 1);
                this.$emit('input', value);
                this.emitChange(value);
                this.$emit('remove-tag', tag.value);
            }
            event.stopPropagation();
        },
        getOption(value) {
            let option;
            const isObject = Object.prototype.toString.call(value).toLowerCase() === '[object object]';
            const isNull = Object.prototype.toString.call(value).toLowerCase() === '[object null]';
            const isUndefined = Object.prototype.toString.call(value).toLowerCase() === '[object undefined]';
            for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
                const cachedOption = this.cachedOptions[i];
                const isEqual = isObject
                    ? getValueByPath(cachedOption.value, this.valueKey) === getValueByPath(value, this.valueKey)
                    : cachedOption.value === value;
                if (isEqual) {
                    option = cachedOption;
                    break;
                }
            }
            if (option) return option;
            const label = (!isObject && !isNull && !isUndefined) ? String(value) : '';
            let newOption = {
                value: value,
                currentLabel: label
            };
            if (this.multiple) {
                newOption.hitState = false;
            }
            return newOption;
        },
        setSelected() {
            if (!this.multiple) {
                this.$nextTick(() => {
                    // 这儿也延迟执行，等cachedOptions变成正确的值
                    let option = this.getOption(this.value);
                    if (option.created) {
                        this.createdLabel = option.currentLabel;
                        this.createdSelected = true;
                    } else {
                        this.createdSelected = false;
                    }
                    this.selectedLabel = option.currentLabel;
                    this.selected = option;
                    if (this.filterable) this.query = this.selectedLabel;
                    this.blur();
                });
            } else {
                let result = [];
                // 解决选择选项时，tag显示对不上的问题，解决方案是等options list排序后再执行
                this.$nextTick(() => {
                    if (Array.isArray(this.value)) {
                        this.value.forEach(value => {
                            result.push(this.getOption(value));
                        });
                    }
                    this.selected = result;
                    this.$emit('get-selected', this.selected);
                    this.$nextTick(() => {
                        this.resetInputHeight();
                    });
                });
            }
        }
    }
};
</script>
<style lang="scss">
.el-select-dropdown__empty {
    font-size: 12px;
    color: #53575B;
    .el-icon-loading {
        font-size: 16px;
    }
}
</style>
