<template>
    <my-custom-select
        ref="elSelect"
        v-model="select"
        v-loadmore="handleScroll"
        class="custom-select"
        :class="className"
        :clearable="clearable"
        :collapse-tags="collapseTags"
        :default-first-option="defaultFirstOption"
        :disabled="disabled"
        :filter-method="filterMethod"
        :filterable="filterable"
        :loading-text="loadingText"
        :loading="remoteLoading"
        :multiple="multiple"
        :placeholder="placeholder"
        :popper-class="popperClass"
        :remote-method="handleRemoteMethod"
        :remote="remote"
        :reserve-keyword="reserveKeyword"
        :value-key="valueKey"
        @change="(value) => {
            $emit('change', value)
        }"
        @clear="handleClear"
        @clearquery="clearQuery"
        @focus="() => {
            $emit('focus', value)
        }"
        @get-query="getQuery"
        @keydown.native="() => {
            $emit('keydown', value)
        }"
        @remove-tag="handleRemoveTag"
        @visible-change="handleVisibleChange"
        @get-selected="getSelectedData"
    >
        <el-option
            v-for="(option, index) in options"
            :key="optionLable + optionValue + index"
            :disabled="option.disabled"
            :label="useTranslate ? $t(option[optionLable]) : option[optionLable]"
            :value="labelAndValue ? option : option[optionValue]"
        >
            <img
                v-if="showItemImage" :src="option.img || bgImg"
                class="preview-img left"
            >
            <span class="left">{{ useTranslate ? $t(option.label) : option.label }}</span>
            <span v-if="showOptionAppend" class="right">{{ option[optionAppendKey] }}</span>
        </el-option>
    </my-custom-select>
</template>

<script>
import utils from '@/common/utils';
import MyCustomSelect from './myCustomSelect.vue';
import bgImg from '@/modules/msn/assets/images/icon/300x175.png';
import Loadmore from './loadmore';
export default {
    name: 'CmpCustomSelect',
    props: {
        keyValue: {
            type: Array,
            default: () => ['label', 'value']
        },
        value: {
            default: ''
        },
        disabled: {
            default: false
        },
        clearable: {
            default: false
        },
        optionList: {
            default: () => []
        },
        multiple: {
            default: true
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        placeholder: {
            default: ''
        },
        filterable: {
            default: true
        },
        collapseTags: {
            default: true
        },
        popperClass: {
            default: 'cmp-select-dropdown'
        },
        useTranslate: {
            default: true
        },
        remote: {
            default: true
        },
        reserveKeyword: {
            default: false
        },
        defaultFirstOption: {
            type: Boolean,
            default: false
        },
        labelAndValue: {
            default: false
        },
        noDataText: {
            default: '无数据'
        },
        loadingText: {
            default: '加载中...'
        },
        showItemImage: Boolean,
        showOptionAppend: Boolean,
        optionAppendKey: {
            type: String,
            default: 'append'
        },
        needPageChange: {
            type: Boolean,
            default: true
        },
        filterMethod: Function,
        originPageInfo: {
            type: [String, Number],
            default: 1
        },
        pageLimit: {
            default: 50
        },
        // remoteMethod: Function,
        loading: {
            default: false
        },
        // 接口参数
        selectParams: {
            type: Object,
            default: () => {}
        },
        // 接口地址
        remoteUrl: String,
        // 接口请求方式
        remoteType: {
            type: String,
            default: 'get'
        },
        // 默认自动请求数据
        automaticQuery: {
            type: Boolean,
            default: true
        },
        sort: Boolean
    },
    components: {
        'my-custom-select': MyCustomSelect
    },
    data() {
        return {
            selectAllStatus: undefined,
            remoteLoading: false,
            bgImg: bgImg,
            query: '',
            pageIndex: 1,
            lastSelected: [],
            options: [],
            currentOptions: [],
            cacheSelected: []
        };
    },
    computed: {
        optionLable() {
            return this.keyValue ? this.keyValue[0] : 'label';
        },
        optionValue() {
            return this.keyValue ? this.keyValue[1] : 'value';
        },
        select: {
            get() {
                this.sortOptions(this.value);
                return this.value;
            },
            set(val) {
                this.sortOptions(val);
                this.$emit('input', val);
            }
        },
        className() {
            let className = 'msn-select';
            if (this.disabled) {
                className = className + ' disabled';
            }
            if (this.collapseTags) {
                className = className + ' collapsed';
            }
            return className;
        }
    },
    directives: { Loadmore },
    watch: {
        loading: {
            immediate: true,
            handler(val) {
                if (!this.automaticQuery) {
                    this.remoteLoading = val;
                }
            }
        },
        optionList: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!(this.remote && this.remoteUrl) || !this.automaticQuery) {
                    this.options = [].concat(val) || [];
                    // this.sortOptions(this.value);
                }
            }
        }
    },
    mounted() {
        this.pageIndex = this.originPageInfo;
    },
    methods: {
        // 获取已选中的数据
        getSelectedData(selected) {
            this.cacheSelected = selected && selected.length ? selected.map(item => {
                return {
                    label: item.currentLabel || item.label,
                    value: item.value
                };
            }) : [];
        },
        getData(cb) {
            this.remoteLoading = true;
            let params = this.selectParams;
            if (this.pageIndex > 1) {
                // 删除已选择id
                params && (delete params.ids);
            }
            if (this.pageIndex === 1) {
                if (this.multiple) {
                    this.select.length && params && (params.ids = this.select.join(';'));
                } else {
                    this.select && params && (params.ids = this.select);
                }
            }
            const keywords = this.query ? this.query.trim() : '';
            this.$request(
                {
                    method: this.remoteType,
                    selectOption: true,
                    url: this.remoteUrl,
                    success(res) {
                        this.remoteLoading = false;
                        let selectedData = !Array.isArray(res.data) ? res.data.selectList : [];
                        let resData = !Array.isArray(res.data) ? res.data.insiderList : res.data;
                        let list = resData || [];
                        let options = [];
                        if (this.pageIndex === 1) {
                            options = utils.uniqObjArr([...selectedData, ...this.cacheSelected, ...list]);
                        } else {
                            options = utils.uniqObjArr([...this.options, ...list]);
                        }
                        this.$set(this, 'options', options);
                        this.$emit('get-select-data', this.options);
                        cb && cb(this.options);
                        if (this.$refs.elSelect?.multiple) {
                            // 解决 多选时 已选和tag显示对不上的问题
                            this.$refs.elSelect?.setSelected();
                        }
                    },
                    error() {
                        this.remoteLoading = false;
                    }
                },
                {...params, currentPage: this.pageIndex, pageSize: this.pageLimit, keywords: keywords}
            );
        },
        getQuery(query) {
            this.pageIndex = this.originPageInfo;
            // this.query = query;
        },
        clearQuery() {
            this.pageIndex = this.originPageInfo;
            if (this.automaticQuery) {
                this.getData();
            } else {
                this.$emit('remoteMethod', {
                    text: undefined,
                    pageIndex: this.pageIndex
                });
            }
        },
        handleRemoteMethod(val) {
            this.query = val;
            if (this.automaticQuery) {
                this.getData();
            } else {
                setTimeout(() => {
                    this.$emit('remoteMethod', {
                        text: val,
                        pageIndex: this.pageIndex
                    });
                }, 300);
            }
        },
        handkeFilterMethod(val) {
            this.$emit('filterMethod', val);
        },
        handleClear(val) {
            this.pageIndex = this.originPageInfo;
            this.$emit('clear', val);
        },
        handleRemoveTag(val) {
            this.$emit('removeTag', val);
        },
        handleVisibleChange(val) {
            // 这儿的val是object，表示是自定义组件
            if (val && val.isCustom) {
                this.pageIndex = this.originPageInfo;
                if (this.automaticQuery) {
                    if (val.val) {
                        this.getData();
                    }
                } else {
                    this.$emit('visibleChange', val.val);
                }
            } else {
                this.query = '';
            }
        },
        handleScroll() {
            if (this.remote && this.needPageChange && this.options.length >= this.pageLimit * this.pageIndex) {
                this.pageIndex++;
                if (this.automaticQuery) {
                    this.getData();
                } else {
                    this.handleRemoteMethod(this.query);
                }
            }
        },
        sortOptions(val) {
            if(!this.sort || !val) return;
            this.options && this.options.length && this.options.sort((a, b) => {
                let selectA = this.multiple ? val.indexOf(a.value) > -1 : val === a.value;
                let selectB = this.multiple ? val.indexOf(b.value) > -1 : val === b.value;
                if (selectA && !selectB) {
                    return -1;
                }
                if (!selectA && selectB) {
                    return 1;
                }
                return 0;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.no-more{
    font-size: 13px;
    color: #53575B !important;
    font-weight: 400;
    text-align: center;
}
.add-item {
    color: #2dd1ac;
    text-align: left;
    font-weight: 700;
    font-size: 13px;
    line-height: 1em;
    margin-top: 15px;
    cursor: pointer;
    padding-left: 25px;
}
.preview-img {
    height: 100%;
    padding: 5px;
    object-fit: cover;
    margin-right: 10px;
    width: 50px;
    box-sizing: border-box;
    &__1{
        padding: 8px;
    }
}
.left {
    float: left;
}
.right {
    float: right;
    color: #8492a6;
    margin-left: 10px;
    margin-right: 20px;
}
.align-left {
    text-align: left;
}
.selected .right {
    color: #2DD1AC;
}
</style>

<style lang="scss">
.el-scrollbar__wrap{
    max-width: 800px;
}
.msn-select {
    height: 35px;
    border-radius: 17.5px;
    box-sizing: border-box;
}
.msn-select.el-select .el-input__inner {
    border: 1px solid transparent;
    height: 35px;
    border-radius: 17.5px;
    background: #EEF1F4;
    font-weight: 500;
    font-size: 13px;
    color: #53575B;
    &:hover {
        border-color: #2DD1AC;
    }
    &:focus {
        border-color: transparent;
    }
}
.el-select-dropdown {
    border: none;
}
.el-form-item.is-error .msn-time.el-date-editor .el-input__inner {
    background-color: rgba(238, 241, 244, 0.5);
    border: 1px solid #E92754;
}
.msn-disabled-option .el-select-dropdown__item.is-disabled {
    opacity: 0.2;
}
.el-select-dropdown__item {
    font-size: 13px;
    color: #53575B !important;
    font-weight: 400;
    text-align: left;
    &.hover {
        background: #EEF4F9 !important;
    }
    &.selected {
        font-weight: 500;
        color: #2DD1AC!important;
    }
}
.el-select .el-input.is-focus .el-input__inner {
    border-color: #2DD1AC !important;
}
.el-select.msn-select .el-select__tags {
    padding-left: 15px;
    .el-select__input {
        margin-left: 0;
    }

    .el-tag.el-tag--info {
        background: #2DD1AC !important;
        border-radius: 13px;
        font-size: 13px;
        color: #fff;
        .el-tag__close {
            color: #fff;
            background-color: #2DD1AC;
            font-size: 1.2em;
        }
    }
}
.msn-select.el-select:hover .el-input__inner {
    border-color: #2DD1AC;
}
.msn-select.el-select.disabled:hover .el-input__inner {
    border: none;
}
.msn-select.el-select.disabled {
    cursor: not-allowed;
    opacity: 0.4;
}
.msn-multi-select.el-select .el-input__inner {
    border-radius: 20px;
}
.msn-is-light {
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, 0.16);
    border-radius: 17.5px;
    .el-input__inner {
        background-color: #fff !important;
    }
}
.el-select__caret {
    line-height: 35px !important;
}
.msn-disabled.el-select.msn-select .el-select__tags .el-tag.el-tag--info .el-tag__close {
    display: none !important;
}
// 多选的tag展示省略
.msn-multi-select {
    display: flex !important;
    align-items: center;
    .el-tag {
        max-width: 40%;
        .el-select__tags-text {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 80%;
        }
        .el-tag__close.el-icon-close {
            // top: -6px;
            // right: 0px;
            position: static;
        }
    }
    &.collapsed {
        .el-tag {
            border: none;
            width: auto;
            .el-select__tags-text {
                &:first-child {
                    display: inline;
                    overflow: visible;
                }
            }
            &:first-child {
                max-width: 40%;
                display: flex;
                align-items: center;
                padding-right: 2px;
                .el-select__tags-text {
                    &:first-child {
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        flex: 1;
                        // min-width: 35px;
                    }
                }
            }
        }
    }
    &.msn-disabled {
        .el-tag {
            &:first-child {
                padding-right: 8px;
            }
        }
    }
}
.msn-multi-select.disabled {
    .el-tag {
        .el-select__tags-text {
            overflow: visible;
        }
    }
}
.el-select.msn-select.msn-is-gray-tag {
    .el-select__tags .el-tag.el-tag--info {
        background: #D5DADF !important;
        color: #53575B;
        .el-tag__close.el-icon-close {
            color: #53575B;
            background-color: transparent !important;
        }
    }
}
</style>
