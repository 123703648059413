/*
 * @file MSN相关的常量
 * @author liushengxgi
 * @date 2020-03-02 15:34:16
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-03-02 15:34:19
 */

export const relationMap = function () {
    return [
        {
            label: 'msn.constants.eq',
            value: 'eq'
        },
        {
            label: 'msn.constants.gt',
            value: 'gt'
        },
        {
            label: 'msn.constants.lt',
            value: 'lt'
        },
        {
            label: 'msn.constants.ge',
            value: 'ge'
        },
        {
            label: 'msn.constants.le',
            value: 'le'
        }
    ];
};

export const imgAccept = 'image/png,image/gif,image/jpeg,image/jpg';

export const dashboardAndCreateMaxImgSize = 5 * 1024 * 1024;
export const dashboardAndCreateMaxGifSize = 10 * 1024 * 1024;

export const timezoneList = [
    {
        label: 'msn.constants.est',
        value: 'EST'
    },
    {
        label: 'UTC+9',
        value: 'UTC+9'
    },
    {
        label: 'msn.constants.utc8',
        value: 'UTC+8'
    },
    {
        label: 'UTC+7',
        value: 'UTC+7'
    },
    {
        label: 'msn.constants.utc0',
        value: 'UTC+0'
    }
];
// 多币种-目前主要在财务用 目前平台所有的货币种类符号都是用的这个
export const newCurrencyList = [
    // 美国
    {
        label: 'msn.constants.usdname',
        value: 'USD',
        symbol: '$',
        finance: 'USD $',
        newSymbol: 'USD $'
    },
    // 日本
    {
        label: 'msn.constants.jpyname',
        value: 'JPY',
        symbol: '¥',
        finance: 'JPY',
        newSymbol: 'JPY ¥'
    },
    // 韩国
    {
        label: 'msn.constants.krwname',
        value: 'KRW',
        symbol: '₩',
        finance: 'KRW',
        newSymbol: 'KRW ₩'
    },
    // 台湾
    {
        label: 'msn.constants.twdname',
        value: 'TWD',
        symbol: 'NT$',
        finance: 'TWD',
        newSymbol: 'TWD NT$'
    },
    // 香港
    {
        label: 'msn.constants.hkdname',
        value: 'HKD',
        symbol: 'HK$',
        finance: 'HKD $',
        newSymbol: 'HKD HK$'
    },
    // 泰国
    {
        label: 'msn.constants.thbname',
        value: 'THB',
        symbol: '฿',
        finance: 'THB',
        newSymbol: 'THB ฿'
    },
    // 印度尼西亚
    {
        label: 'msn.constants.idrname',
        value: 'IDR',
        symbol: 'Rp',
        finance: 'IDR',
        newSymbol: 'IDR Rp'
    },
    // 马来西亚
    {
        label: 'msn.constants.myrname',
        value: 'MYR',
        symbol: 'RM',
        finance: 'MYR',
        newSymbol: 'MYR RM'
    },
    // 新加坡
    {
        label: 'msn.constants.sgdname',
        value: 'SGD',
        symbol: 'S$',
        finance: 'SGD',
        newSymbol: 'SGD S$'
    },
    // 中国
    {
        label: 'msn.constants.cnyname',
        value: 'CNY',
        symbol: 'CNY￥',
        finance: 'CNY',
        newSymbol: 'CNY ￥'
    }

];
// 注册结算币种
export const currencyList = [
    // 日本
    {
        label: 'msn.constants.jpyname',
        value: 'JPY',
        symbol: '¥',
        finance: 'JPY'
    },
    // 美国
    {
        label: 'msn.constants.usdname',
        value: 'USD',
        symbol: '$',
        finance: 'USD $'
    }
];
// 新增结算币种，主要在权限，开户，自助开户页面使用
export const settlementCurrencyList = [
    // 日本
    {
        label: 'msn.constants.jpyname',
        value: 'JPY',
        symbol: '¥',
        finance: 'JPY'
    },
    // 美国
    {
        label: 'msn.constants.usdname',
        value: 'USD',
        symbol: '$',
        finance: 'USD $'
    },
    // 韩国
    {
        label: 'msn.constants.krwname',
        value: 'KRW',
        symbol: '₩',
        finance: 'KRW'
    },
    // 台湾
    {
        label: 'msn.constants.twdname',
        value: 'TWD',
        symbol: '$',
        finance: 'TWD'
    }
];
// BD开户新增合同
export const contractualList = [
    {
        label: 'msn.constants.mediago',
        value: 'mediago'
    },
    {
        label: 'msn.constants.jpSubject',
        value: 'jp.popin.cc'
    },
    {
        label: 'msn.constants.krSubject',
        value: 'kr.popin.cc'
    },
    {
        label: 'msn.constants.twSubject',
        value: 'tw.popin.cc'
    }
];
export const newTimezoneList = [
    {
        label: 'msn.constants.est',
        value: 'EST'
    },
    {
        label: 'msn.constants.utc8',
        value: 'UTC8'
    },
    {
        label: 'msn.constants.utc0',
        value: 'UTC0'
    }
];
export const latitudeOptions = [
    {
        label: 'msn.constants.daily',
        value: '1'
    },
    {
        label: 'msn.constants.hourly',
        value: '2'
    }
];
export const timezoneOffset = {
    EST: -5,
    'UTC+0': 0,
    'UTC+8': 8,
    'UTC+9': 9,
    'UTC+7': 7
};
// moment-timezone 对照表
export const timeZoneMap = {
    EST: 'EST5EDT',
    'UTC+8': 'Etc/GMT-8',
    'UTC+0': 'Etc/GMT-0',
    'UTC+9': 'Etc/GMT-9',
    'UTC+7': 'Etc/GMT-7'
};

// 域名参数
export const DOMAIN_MAP = {
    us: 'business.mediago.io',
    cn: 'business-cn.mediago.io'
};

// 广告分类如下（分类先只用英文）
export const industryListNew = [
    {
        label: 'msn.constants.auto',
        value: 'Auto'
    },
    {
        label: 'msn.constants.beautyFitness',
        value: 'Beauty & Fitness'
    },
    {
        label: 'msn.constants.beautyHairCare',
        value: 'Beauty-Hair Care'
    },
    {
        label: 'msn.constants.businessAndFinance',
        value: 'Business and Finance'
    },
    {
        label: 'msn.constants.careers',
        value: 'Careers'
    },
    {
        label: 'msn.constants.computerElectronics',
        value: 'Computer & Electronics'
    },
    {
        label: 'msn.constants.eCommerce',
        value: 'E-commerce'
    },
    {
        label: 'msn.constants.education',
        value: 'Education'
    },
    {
        label: 'msn.constants.entertainment',
        value: 'Entertainment'
    },
    {
        label: 'msn.constants.eventsAndAttractions',
        value: 'Events and Attractions'
    },
    {
        label: 'msn.constants.familyAndRelationships',
        value: 'Family and Relationships'
    },
    {
        label: 'msn.constants.financeInsurance',
        value: 'Finance & Insurance'
    },
    {
        label: 'msn.constants.food',
        value: 'Food'
    },
    {
        label: 'msn.constants.gaming',
        value: 'Gaming'
    },
    {
        label: 'msn.constants.healthcare',
        value: 'Healthcare'
    },
    {
        label: 'msn.constants.homeGarden',
        value: 'Home & Garden'
    },
    {
        label: 'msn.constants.leadGen',
        value: 'Lead Gen'
    },
    {
        label: 'msn.constants.lifestyle',
        value: 'Lifestyle'
    },
    {
        label: 'msn.constants.newsAndPolitics',
        value: 'News and Politics'
    },
    {
        label: 'msn.constants.pets',
        value: 'Pets'
    },
    {
        label: 'msn.constants.personalCareOralCare',
        value: 'Personal Care-Oral care'
    },
    {
        label: 'msn.constants.popCulture',
        value: 'Pop Culture'
    },
    {
        label: 'msn.constants.realEstate',
        value: 'Real Estate'
    },
    {
        label: 'msn.constants.religionSpiritualty',
        value: 'Religion & Spirituality'
    },
    {
        label: 'msn.constants.science',
        value: 'Science'
    },
    {
        label: 'msn.constants.sports',
        value: 'Sports'
    },
    {
        label: 'msn.constants.travelHospitality',
        value: 'Travel & Hospitality'
    },
    {
        label: 'msn.constants.weightLoss',
        value: 'Weight Loss'
    },
    {
        label: 'msn.constants.wellness',
        value: 'Wellness'
    },
    {
        label: 'msn.constants.others',
        value: 'Others'
    }

];

// 行业列表
export const industryList = [
    {
        label: 'msn.constants.auto',
        value: 'Auto'
    },
    {
        label: 'msn.constants.beautyFitness',
        value: 'Beauty & Fitness'
    },
    {
        label: 'msn.constants.computerElectronics',
        value: 'Computer & Electronics'
    },
    {
        label: 'msn.constants.eCommerce',
        value: 'E-commerce'
    },
    {
        label: 'msn.constants.education',
        value: 'Education'
    },
    {
        label: 'msn.constants.entertainment',
        value: 'Entertainment'
    },
    {
        label: 'msn.constants.financeInsurance',
        value: 'Finance & Insurance'
    },
    {
        label: 'msn.constants.food',
        value: 'Food'
    },
    {
        label: 'msn.constants.Gaming',
        value: 'Gaming'
    },
    {
        label: 'msn.constants.healthcare',
        value: 'Healthcare'
    },
    {
        label: 'msn.constants.leadGen',
        value: 'Lead Gen'
    },
    {
        label: 'msn.constants.lifestyle',
        value: 'Lifestyle'
    },
    {
        label: 'msn.constants.sports',
        value: 'Sports'
    },
    {
        label: 'msn.constants.travelHospitality',
        value: 'Travel & Hospitality'
    },
    {
        label: 'msn.constants.others',
        value: 'Others'
    }
];

// 账户所属行业分类列表
export const tradeTypeList = [
    {
        label: 'msn.constants.eCommerce',
        value: 'E-commerce'
    },
    {
        label: 'msn.constants.content',
        value: 'Content'
    },
    {
        label: 'msn.constants.leadGen',
        value: 'Lead Gen'
    },
    {
        label: 'msn.constants.search',
        value: 'Search'
    },
    {
        label: 'msn.constants.health',
        value: 'Health'
    },
    {
        label: 'msn.constants.realEstate',
        value: 'Real estate'
    },
    {
        label: 'msn.constants.finance',
        value: 'Finance'
    },
    {
        label: 'msn.constants.insurance',
        value: 'Insurance'
    },
    {
        label: 'msn.constants.education',
        value: 'Education'
    },
    {
        label: 'msn.constants.recruitment',
        value: 'Recruitment'
    },
    {
        label: 'msn.constants.travel',
        value: 'Travel'
    },
    {
        label: 'msn.constants.marriage',
        value: 'Marriage'
    },
    {
        label: 'msn.constants.auto',
        value: 'Auto'
    },
    {
        label: 'msn.constants.medicalIndustry',
        value: 'Medical industry'
    },
    {
        label: 'msn.constants.diet',
        value: 'Diet'
    },
    {
        label: 'msn.constants.beautyCosmetics',
        value: 'Beauty cosmetics'
    },
    {
        label: 'msn.constants.homeAppliances',
        value: 'Home appliances'
    },
    {
        label: 'msn.constants.computer',
        value: 'Computer'
    },
    {
        label: 'msn.constants.communication',
        value: 'Communication'
    },
    {
        label: 'msn.constants.media',
        value: 'Media'
    },
    {
        label: 'msn.constants.gaming',
        value: 'Game'
    },
    {
        label: 'msn.constants.entertainment',
        value: 'Entertainment'
    },
    {
        label: 'msn.constants.physicalEducation',
        value: 'Physical education'
    },
    {
        label: 'msn.constants.others',
        value: 'Others'
    }
];
// 帐户服务类型
export const serviceType = [
    {
        label: 'msn.supplement.openAccountIndependently',
        value: '0'
    },
    {
        label: 'msn.supplement.inviteToOpenAccount',
        value: '1'
    }
];

export const MODULE_PLATFORM = 'platform';

/**
 * @const 代理管理公共const
 */
//  账户类型list
export const accountTypeList = [
    {
        label: 'msn.supplement.businessManagement',
        value: 'Aggregator'
    },
    {
        label: 'msn.supplement.advertiser',
        value: 'Advertiser'
    }
];
// tip 提示
export const tipData = [
    {
        user: 'AM & BD用户',
        account: '用户权限的可读账户增加该账户',
        bdAccount: '用户权限的BM账户增加该商务管理账户，同时可读账户增加所有代理商账户为该BM账户的账户'
    },
    {
        user: 'BM账户对应的用户',
        account: '',
        bdAccount: '用户权限的BM账户增加该商务管理账户，同时可读账户增加所有代理商账户为该BM账户的账户'
    },
    {
        user: '广告主用户',
        account: '用户权限的可写、可读账户增加该账户',
        bdAccount: ''
    }
];
// 账户来源
export const accountOriginList = [
    {
        value: 'MediaGo',
        label: 'MediaGo'
    },
    {
        value: 'Discovery',
        label: 'Discovery'
    }
];

// 合同主体
export const contractualSubjectList = [
    {
        value: 'mediago',
        label: 'msn.constants.mediago'
    },
    {
        value: 'jp.popin.cc',
        label: 'msn.constants.jpSubject'
    },
    {
        value: 'kr.popin.cc',
        label: 'msn.constants.krSubject'
    },
    {
        value: 'tw.popin.cc',
        label: 'msn.constants.twSubject'
    },
    {
        value: 'discovery线上合同',
        label: 'msn.supplement.discoveryOnline'
    }
];
// 状态列表
export const statusList = [
    {
        label: 'msn.dashboard.off',
        value: 0
    },
    {
        label: 'msn.dashboard.on',
        value: 1
    },
    {
        label: 'msn.audit.inReview',
        value: 2
    },
    {
        label: 'msn.audit.fail',
        value: 3
    }
];
// apiToken
export const apiTokenList = [
    {
        label: 'msn.supplement.aPIhasinstalled',
        value: 1
    },
    {
        label: 'msn.supplement.withoutSettingAPI',
        value: 0
    }
];

/**
 * @const 创建广告公共const 目前只抽离了国家部分常量，太多了。。。
 */

// 州列表
export const cityMap = [
    // 'All',
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WAI',
    'WV',
    'WI',
    'WY'
];

// 加拿大
export const caState = [
    // 'All',
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'ON',
    'PE',
    'QC',
    'SK',
    'NT',
    'NU',
    'YT'
];

// 澳大利亚
export const auState = [
    'NSW',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA',
    'ACT',
    'NT01'
];

// 英国
export const ukState = [
    'ENG',
    'NIR',
    'SCT',
    'WLS'
];

// 日本
export const jpState = [
    'JP13',
    'JP01',
    'JP26',
    'JP27',
    'JP02',
    'JP03',
    'JP04',
    'JP05',
    'JP06',
    'JP07',
    'JP08',
    'JP09',
    'JP10',
    'JP11',
    'JP12',
    'JP14',
    'JP15',
    'JP16',
    'JP17',
    'JP18',
    'JP19',
    'JP20',
    'JP21',
    'JP22',
    'JP23',
    'JP24',
    'JP25',
    'JP28',
    'JP29',
    'JP30',
    'JP31',
    'JP32',
    'JP33',
    'JP34',
    'JP35',
    'JP36',
    'JP37',
    'JP38',
    'JP39',
    'JP40',
    'JP41',
    'JP42',
    'JP43',
    'JP44',
    'JP45',
    'JP46',
    'JP47'
];

// 印尼
export const idState = [
    'IDAC',
    'IDBA',
    'IDBB',
    'IDBT',
    'IDBE',
    'IDGO',
    'IDJA',
    'IDJB',
    'IDJT',
    'IDJI',
    'IDKB',
    'IDKS',
    'IDKT',
    'IDKI',
    'IDKU',
    'IDKR',
    'IDLA',
    'IDMA',
    'IDMU',
    'IDNB',
    'IDNT',
    'IDPA',
    'IDPB',
    'IDRI',
    'IDSR',
    'IDSN',
    'IDST',
    'IDSG',
    'IDSA',
    'IDSB',
    'IDSS',
    'IDSU',
    'IDJK',
    'IDYO'
];

// 台湾
export const twState = [
    'NWT',
    'TPE',
    'TAO',
    'TXG',
    'TNN',
    'KHH',
    'ILA',
    'HSQ',
    'MIA',
    'CHA',
    'NAN',
    'YUN',
    'CYQ',
    'PIF',
    'TTT',
    'HUA',
    'PEN',
    'KEE'
];

// 韩国
export const krState = [
    'KR11',
    'KR26',
    'KR27',
    'KR28',
    'KR29',
    'KR30',
    'KR31',
    'KR50',
    'KR41',
    'KR42',
    'KR43',
    'KR44',
    'KR45',
    'KR46',
    'KR47',
    'KR48',
    'KR49'
];
// 泰国
export const thState = [];
// 新加坡
export const sgState = [];
// 马来西亚
export const myState = [];
// 香港
export const hkState = [];

// 受众列表
export const audienceList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.news',
        value: 'news'
    },
    {
        label: 'msn.constants.weather',
        value: 'weather'
    },
    {
        label: 'msn.constants.election',
        value: 'election'
    },
    {
        label: 'msn.constants.entertainment',
        value: 'entertainment'
    },
    {
        label: 'msn.constants.sports',
        value: 'sports'
    },
    {
        label: 'msn.constants.finance',
        value: 'finance'
    },
    {
        label: 'msn.constants.lifestyle',
        value: 'lifestyle'
    },
    {
        label: 'msn.constants.health',
        value: 'health'
    },
    {
        label: 'msn.constants.foodDrink',
        value: 'foodanddrink'
    },
    {
        label: 'msn.constants.travel',
        value: 'travel'
    },
    {
        label: 'msn.constants.car',
        value: 'autos'
    },
    {
        label: 'msn.constants.video',
        value: 'video'
    },
    // {
    //     label: 'Kids',
    //     value: 'kids'
    // },
    {
        label: 'msn.constants.money',
        value: 'money'
    },
    {
        label: 'msn.constants.TV',
        value: 'tv'
    },
    {
        label: 'msn.constants.movies',
        value: 'movies'
    },
    {
        label: 'msn.constants.music',
        value: 'music'
    },
    {
        label: 'msn.constants.homepage',
        value: 'hp'
    }
    // {
    //     label: 'For Good',
    //     value: 'for good'
    // }
];
// All受众列表
export const allAudienceData = {
    all: [
        {
            label: 'msn.constants.all',
            value: 'All'
        },
        {
            label: 'msn.constants.autoselectronics', // 汽车与电子类
            value: 'autos'
        },
        {
            label: 'msn.constants.business',
            value: 'business'
        },
        {
            label: 'msn.constants.beautyFashion',
            value: 'beautyandfashion'
        },
        {
            label: 'msn.constants.entertainment',
            value: 'entertainment'
        },
        {
            label: 'msn.constants.economyMoney', // 经济与金钱类
            value: 'money'
        },
        {
            label: 'msn.constants.election',
            value: 'election'
        },
        {
            label: 'msn.constants.education',
            value: 'education'
        },
        {
            label: 'msn.constants.finance',
            value: 'finance'
        },
        {
            label: 'msn.constants.foodDrink',
            value: 'foodanddrink'
        },
        {
            label: 'msn.constants.homepage',
            value: 'hp'
        },
        {
            label: 'msn.constants.health',
            value: 'health'
        },
        {
            label: 'msn.constants.homeLifestyle', // 家居与生活类
            value: 'lifestyle'
        },
        {
            label: 'msn.constants.loveMarriage',
            value: 'loveandmarriage'
        },
        {
            label: 'msn.constants.music',
            value: 'music'
        },
        {
            label: 'msn.constants.movies',
            value: 'movies'
        },
        {
            label: 'msn.constants.news',
            value: 'news'
        },
        {
            label: 'msn.constants.politicsSocial',
            value: 'politicsandsocial'
        },
        {
            label: 'msn.constants.sports',
            value: 'sports'
        },
        {
            label: 'msn.constants.travel',
            value: 'travel '
        },
        {
            label: 'msn.constants.TV',
            value: 'tv'
        },
        {
            label: 'msn.constants.video',
            value: 'video'
        },
        {
            label: 'msn.constants.weather',
            value: 'weather'
        }
    ],
    europe_and_america: [
        {
            label: 'msn.constants.all',
            value: 'All'
        },
        {
            label: 'msn.constants.car',
            value: 'autos'
        },
        {
            label: 'msn.constants.entertainment',
            value: 'entertainment'
        },
        {
            label: 'msn.constants.money',
            value: 'money'
        },
        {
            label: 'msn.constants.election',
            value: 'election'
        },
        {
            label: 'msn.constants.finance',
            value: 'finance'
        },
        {
            label: 'msn.constants.foodDrink',
            value: 'foodanddrink'
        },
        {
            label: 'msn.constants.homepage',
            value: 'hp'
        },
        {
            label: 'msn.constants.health',
            value: 'health'
        },
        {
            label: 'msn.constants.lifestyle',
            value: 'lifestyle'
        },
        {
            label: 'msn.constants.music',
            value: 'music'
        },
        {
            label: 'msn.constants.movies',
            value: 'movies'
        },
        {
            label: 'msn.constants.news',
            value: 'news'
        },
        {
            label: 'msn.constants.sports',
            value: 'sports'
        },
        {
            label: 'msn.constants.travel',
            value: 'travel '
        },
        {
            label: 'msn.constants.TV',
            value: 'tv'
        },
        {
            label: 'msn.constants.video',
            value: 'video'
        },
        {
            label: 'msn.constants.weather',
            value: 'weather'
        }
    ],
    japan: [
        {
            label: 'msn.constants.all',
            value: 'All'
        },
        {
            label: 'msn.constants.gadgetsAutos', // 工具和汽车类
            value: 'autos'
        },
        {
            label: 'msn.constants.business',
            value: 'business'
        },
        {
            label: 'msn.constants.beautyFashion', // 美容与时尚类
            value: 'beautyandfashion'
        },
        {
            label: 'msn.constants.entertainment',
            value: 'entertainment'
        },
        {
            label: 'msn.constants.economyMoney', // 经济与金钱类
            value: 'money'
        },
        {
            label: 'msn.constants.health',
            value: 'health'
        },
        {
            label: 'msn.constants.homeLifestyle', // 家居与生活类
            value: 'lifestyle'
        },
        {
            label: 'msn.constants.education',
            value: 'education'
        },
        {
            label: 'msn.constants.loveMarriage', // 爱情与婚姻类
            value: 'loveandmarriage'
        },
        {
            label: 'msn.constants.politicsSocial', // 政治与社会类
            value: 'politicsandsocial'
        },
        {
            label: 'msn.constants.sports',
            value: 'sports'
        },
        {
            label: 'msn.constants.travelGourment', // 旅游与美食类
            value: 'travel'
        },
        {
            label: 'msn.constants.foodDrink', // 旅游与美食类
            value: 'foodanddrink'
        }
    ]
};

// 国家列表
export const countryList = [
    {
        label: 'msn.create.countryUsa',
        belong: 'occident',
        value: 'US'
    },
    {
        label: 'msn.create.countryCanada',
        belong: 'occident',
        value: 'CA'
    },
    {
        label: 'msn.create.gb',
        belong: 'occident',
        value: 'GB'
    },
    {
        label: 'msn.create.au',
        belong: 'occident',
        value: 'AU'
    }
];
// 东南亚国家列表
export const southeastCountryList = [
    {
        label: 'msn.create.jp',
        belong: 'southEastAsia',
        value: 'JP'
    },
    {
        label: 'msn.create.kr',
        belong: 'southEastAsia',
        value: 'KR'
    },
    {
        label: 'msn.create.tw',
        belong: 'southEastAsia',
        value: 'TW'
    },
    {
        label: 'msn.create.id',
        belong: 'southEastAsia',
        value: 'ID'
    },
    {
        label: 'msn.create.sg',
        belong: 'southEastAsia',
        value: 'SG'
    },
    {
        label: 'msn.create.my',
        belong: 'southEastAsia',
        value: 'MY'
    },
    {
        label: 'msn.create.th',
        belong: 'southEastAsia',
        value: 'TH'
    },
    {
        label: 'msn.create.hk',
        belong: 'southEastAsia',
        value: 'HK'
    }
];

// 语言反选
export const languageMap = function (v) {
    // console.log(v);
    const languages = [];
    newLanguageList.forEach(lang => {
        languages.push(...lang.languages);
    });
    // console.log(languages);
    const target = languages.find(e => e.value.toLowerCase() === v.toLowerCase());
    // console.log(target);
    return target ? target.label : '';
};

// 语言选择 1111
export const languageList = [
    {
        label: 'msn.create.English',
        value: 'en'
    },
    {
        label: 'msn.create.Spanish',
        value: 'es'
    },
    {
        label: 'msn.create.All',
        value: 'All'
    }
];

// All
export const optList = [
    {
        label: 'msn.create.allSelect',
        value: 'All'
    }
];

// exclude / include
export const optionList = [
    ...optList,
    {
        label: 'msn.create.include',
        value: 0
    },
    {
        label: 'msn.create.exclude',
        value: 1
    }
];


// 在媒体定向下可定向或排除 MY/HK特殊IP
export const onlyOptionList = [
    {
        label: 'msn.create.include',
        value: 0
    },
    {
        label: 'msn.create.exclude',
        value: 1
    }
];

export const optionMap = function (v, vm) {
    const target = optionList.find(e => e.value === v);
    if (vm) {
        // 应该用 vm.$t 的 但是逻辑上没问题不改了
        return this.$t(target.label);
    } else {
        return target ? target.label : '';
    }
};

// 特殊tw state
export const extraState = [
    { label: 'msn.create.my', value: 'MY' },
    { label: 'msn.create.hk', value: 'HK' }
];

// type: state / zip code
export const typeList = (country) => {
    switch (country) {
        case 'US': {
            return [
                {
                    label: 'msn.create.state',
                    value: 0
                },
                {
                    label: 'msn.create.zipcode',
                    value: 1
                }
            ];
        }
        case 'CA': {
            return [
                {
                    label: 'msn.create.ca_state',
                    value: 0
                }
            ];
        }
        case 'AU': {
            return [
                {
                    label: 'msn.create.state',
                    value: 0
                }
            ];
        }
        case 'GB': {
            return [
                {
                    label: 'msn.create.ca_state',
                    value: 0
                }
            ];
        }
        case 'JP': {
            return [
                {
                    label: 'msn.create.jp_state',
                    value: 0
                }
            ];
        }
        case 'KR': {
            return [
                {
                    label: 'msn.create.kr_state',
                    value: 0
                }
            ];
        }
        case 'TW': {
            return [
                {
                    label: 'msn.create.tw_state',
                    value: 0
                }
            ];
        }
        case 'ID': {
            return [
                {
                    label: 'msn.create.id_state',
                    value: 0
                }
            ];
        }
        case 'SG': {
            return [
                {
                    label: 'msn.create.sg_state',
                    value: 0
                }
            ];
        }
        case 'MY': {
            return [
                {
                    label: 'msn.create.my_state',
                    value: 0
                }
            ];
        }
        case 'TH': {
            return [
                {
                    label: 'msn.create.th_state',
                    value: 0
                }
            ];
        }
        case 'HK': {
            return [
                {
                    label: 'msn.create.hk_state',
                    value: 0
                }
            ];
        }
        case 'DE': {
            return [
                {
                    label: 'msn.create.deLang',
                    value: 0
                }
            ];
        }
        case 'FR': {
            return [
                {
                    label: 'msn.create.frLang',
                    value: 0
                }
            ];
        }
        case 'IT': {
            return [
                {
                    label: 'msn.create.itLang',
                    value: 0
                }
            ];
        }
        case 'ES': {
            return [
                {
                    label: 'msn.create.Spanish',
                    value: 0
                }
            ];
        }
        case 'AT': {
            return [
                {
                    label: 'msn.create.deLang',
                    value: 0
                }
            ];
        }

        default: {
            return [];
        }
    }
};

export const typeMap = function (v, vm) {
    const target = typeList.find(e => e.value === v);
    if (vm) {
        return this.$t(target.label);
    } else {
        return target.label;
    }
};

// 多国家 多语言
export const newLanguageList = [
    {
        label: 'USA',
        value: 'US',
        languages: [{
            label: 'msn.create.English',
            value: 'en'
        },
        {
            label: 'msn.create.Spanish',
            value: 'es'
        }]
    },
    {
        label: 'CA',
        value: 'CA',
        languages: [{
            label: 'msn.create.English',
            value: 'en'
        }]
    },
    {
        label: 'GB',
        value: 'GB',
        languages: [{
            label: 'msn.create.English',
            value: 'en'
        }]
    },
    {
        label: 'AU',
        value: 'AU',
        languages: [{
            label: 'msn.create.English',
            value: 'en'
        }]
    },
    {
        // 日
        label: 'JP',
        value: 'JP',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.Japanese',
            value: 'ja'
        }]
    },
    {
        // 韩
        label: 'KR',
        value: 'KR',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.Korean',
            value: 'ko'
        }]
    },
    {
        // 台
        label: 'TW',
        value: 'TW',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.English',
            value: 'en'
        },
        {
            label: 'msn.create.newCountryLang.Chinese',
            value: 'zh'
        }]
    },
    {
        // 印尼
        label: 'ID',
        value: 'ID',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.Indonesian',
            value: 'id'
        },
        {
            label: 'msn.create.newCountryLang.English',
            value: 'en'
        }]
    },
    {
        // 新
        label: 'SG',
        value: 'SG',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.English',
            value: 'en'
        }]
    },
    {
        // 马
        label: 'MY',
        value: 'MY',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.Malay',
            value: 'ml'
        },
        {
            label: 'msn.create.newCountryLang.English',
            value: 'en'
        },
        {
            label: 'msn.create.newCountryLang.Chinese',
            value: 'zh'
        }]
    },
    {
        // 泰
        label: 'TH',
        value: 'TH',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.Thai',
            value: 'th'
        }]
    },
    {
        // 香港
        label: 'HK',
        value: 'HK',
        languages: [{
            label: 'msn.create.All',
            value: 'All'
        },
        {
            label: 'msn.create.newCountryLang.English',
            value: 'en'
        },
        {
            label: 'msn.create.newCountryLang.Chinese',
            value: 'zh'
        }]
    },
    {
        // 德国
        label: 'DE',
        value: 'DE',
        languages: [{
            label: 'msn.create.deLang',
            value: 'de'
        }]
    },
    {
        // 法国
        label: 'FR',
        value: 'FR',
        languages: [{
            label: 'msn.create.frLang',
            value: 'fr'
        }]
    },
    {
        // 意大利
        label: 'IT',
        value: 'IT',
        languages: [{
            label: 'msn.create.itLang',
            value: 'it'
        }]
    },
    {
        // 西班牙
        label: 'ES',
        value: 'ES',
        languages: [{
            label: 'msn.create.Spanish',
            value: 'es'
        }]
    },
    {
        // aodili
        label: 'AT',
        value: 'AT',
        languages: [{
            label: 'msn.create.deLang',
            value: 'de'
        }]
    }


    // 暂时不做这些语言联动的，value 给个 All
    // {
    //     label: 'JP',
    //     value: 'JP',
    //     languages: [{
    //         label: 'msn.create.Japanese',
    //         value: 'ja'
    //     }]
    // }
];

// 多语言反选
export const newLanguageMap = function (v, country) {
    const target = newLanguageList.find(e => e.value === country);
    // console.log(target);
    if (target) {
        const res = target.languages.find(e => e.value.toLowerCase() === v.toLowerCase());
        return '' + res.label;
    } else {
        return '';
    }
};

// 欧洲国家列表
export const euCountry = [
    {
        label: 'msn.create.de',
        belong: 'occident',
        value: 'DE'
    },
    {
        label: 'msn.create.fr',
        belong: 'occident',
        value: 'FR'
    },
    {
        label: 'msn.create.it',
        belong: 'occident',
        value: 'IT'
    },
    {
        label: 'msn.create.es',
        belong: 'occident',
        value: 'ES'
    },
    {
        label: 'msn.create.at',
        belong: 'occident',
        value: 'AT'
    }
];

// 国家选择
export const advertiseCountryList = [
    {
        label: 'msn.create.countryUsa',
        belong: 'occident',
        value: 'US'
    },
    {
        label: 'msn.create.countryCanada',
        belong: 'occident',
        value: 'CA'
    },
    {
        label: 'msn.create.gb',
        belong: 'occident',
        value: 'GB'
    },
    {
        label: 'msn.create.au',
        belong: 'occident',
        value: 'AU'
    }

];

// 所有国家列表
export const allCountryList = [
    ...countryList,
    ...southeastCountryList
];

// 是否地区国家列表
export const isRegionCountryList = [
    ...advertiseCountryList,
    ...southeastCountryList
];

// 国家反选
export const countryMap = function (v, vm) {
    const target = [...allCountryList, ...euCountry].find(e => e.value === v);
    if (target) {
        if (vm) {
            if (target) {
                return this.$t(target.label);
            } else {
                switch (v) {
                    case 'GB': {
                        return this.$t('msn.create.gb');
                    }
                    case 'AU': {
                        return this.$t('msn.create.au');
                    }
                    default: {
                        return v;
                    }
                }
            }
        } else {
            return target.label;
        }
    }
};

// 限制数据
export const restrictData = {
    blue: ['business', 'beautyandfashion', 'loveandmarriage', 'politicsandsocial'],
    yellow: ['election', 'finance', 'foodanddrink', 'homepage', 'music', 'movies', 'news', 'tv', 'video', 'weather']
};
// 日期开始类型
export const dateStartTypeOptions = [
    {
        label: 'msn.create.immediately',
        value: 'immediately'
    },
    {
        label: 'msn.create.setTime',
        value: 'other'
    }
];

// 日期选择条件选项
export const dateEndTypeOptions = [
    {
        label: 'msn.create.indefinitely',
        value: 'indefinitely'
    },
    {
        label: 'msn.create.setTime',
        value: 'other'
    }
];
// 总预算设置
export const lifetimeCapOption = [
    {
        label: 'msn.create.lifetimeBudget',
        value: 0
    },
    {
        label: 'msn.create.monthlyBudget',
        value: 1
    }
];
// 广告投放目标
export const objectiveOption = [
    {
        label: 'msn.create.objectiveTraffic',
        value: 'traffic'
    },
    {
        label: 'msn.create.objectiveAwareness',
        value: 'awareness'
    },
    {
        label: 'msn.create.objectiveConversions',
        value: 'conversions'
    },
    {
        label: 'msn.create.objectiveLead',
        value: 'lead'
    }
];
export const dObjectiveOption = [
    {
        label: 'msn.create.purchaseOrder',
        type: 'completeConversion',
        value: 'conversions'
    },
    {
        label: 'msn.create.fillForm',
        type: 'willingnessToInteract',
        value: 'fillOutTheForm'
    },
    {
        label: 'msn.create.signUp',
        type: 'willingnessToInteract',
        value: 'lead'
    },
    {
        label: 'msn.create.downLoadData',
        type: 'willingnessToInteract',
        value: 'downloadData'
    },
    {
        label: 'msn.create.sendMessage',
        type: 'willingnessToInteract',
        value: 'sendMessage'
    },
    {
        label: 'msn.create.browseWebpage',
        type: 'willingnessToInteract',
        value: 'traffic'
    },
    // {
    //     label: 'msn.create.promotionObjective',
    //     type: 'willingnessToInteract',
    //     value: 'promotionTarget'
    // },
    // {
    //     label: 'msn.create.completeConversion',
    //     value: 'completeConversion'
    // },
    // {
    //     label: 'msn.create.willingnessToInteract',
    //     value: 'willingnessToInteract'
    // },
    // {
    //     label: 'msn.create.reputation',
    //     value: 'reputation'
    // },
    {
        label: 'msn.create.enhanceBrand',
        type: 'reputation',
        value: 'awareness'
    }
];
// 视觉尺寸图
export const visualSizeMap = {
    '622x368': {
        scale: 0.4,
        width: 622,
        height: 368,
        default: require('@/modules/msn/assets/images/icon/622x368.png'),
        url: '',
        // 用于判断是否可以选中：根据placement来判断
        disabled: false,
        // 用于标记当前状态
        selected: 1
    },
    '207x311': {
        scale: 0.472,
        width: 207,
        height: 311,
        default: require('@/modules/msn/assets/images/icon/207x311.png'),
        url: '',
        // 用于判断是否可以选中：根据placement来判断
        disabled: false,
        // 用于标记当前状态
        selected: 1
    },
    // '300x387': {
    //     scale: 0.38,
    //     width: 300,
    //     height: 387,
    //     default: require('@/modules/msn/assets/images/icon/300x387.png'),
    //     url: '',
    //     // 用于判断是否可以选中：根据placement来判断
    //     disabled: false,
    //     // 用于标记当前状态
    //     selected: 1
    // },
    '311x333': {
        scale: 0.44,
        width: 311,
        height: 333,
        default: require('@/modules/msn/assets/images/icon/311x333.png'),
        url: '',
        // 用于判断是否可以选中：根据placement来判断
        disabled: false,
        // 用于标记当前状态
        selected: 1
    },
    /* '300x397': {
        placements: ['River'],
        scale: 0.37,
        width: 300,
        height: 397,
        default: require('@/modules/msn/assets/images/icon/300x397-closed.png'),
        url: '',
        disabled: false,
        selected: 1,
        closed: true
    }, */
    '300x175': {
        scale: 0.57,
        width: 300,
        height: 175,
        default: require('@/modules/msn/assets/images/icon/300x175.png'),
        url: '',
        disabled: false,
        selected: 1
    },
    '300x157': {
        scale: 0.64,
        width: 300,
        height: 157,
        default: require('@/modules/msn/assets/images/icon/300x175.png'),
        url: '',
        disabled: false,
        selected: 1
    },
    // '200x100': {
    //     scale: 1,
    //     width: 200,
    //     height: 100,
    //     default: require('@/modules/msn/assets/images/icon/200x100-closed.png'),
    //     url: '',
    //     disabled: false,
    //     selected: 1,
    //     closed: true
    // },
    '128x72': {
        scale: 1,
        width: 128,
        height: 72,
        default: require('@/modules/msn/assets/images/icon/128x72.png'),
        url: '',
        disabled: false,
        selected: 1
    },
    '100x75': {
        scale: 1,
        width: 100,
        height: 75,
        default: require('@/modules/msn/assets/images/icon/100x75.png'),
        url: '',
        disabled: false,
        selected: 1
    },
    '90x90': {
        scale: 0.833,
        width: 90,
        height: 90,
        default: require('@/modules/msn/assets/images/icon/90x90.png'),
        url: '',
        disabled: false,
        selected: 1
    }
    // 以下尺寸目前不在页面展示
    // '492x328': {
    //     scale: 0.833,
    //     width: 492,
    //     height: 328,
    //     default: '',
    //     url: '',
    //     disabled: false,
    //     selected: 1,
    //     hide: true
    // },
};
// 设备类型
export const deviceList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.platform',
        value: 'platform'
    },
    {
        label: 'msn.constants.os',
        value: 'os'
    },
    {
        label: 'msn.constants.browser',
        value: 'browser'
    }
];
// 平台列表
export const platformList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.mobile',
        value: 'Mobile'
    },
    {
        label: 'msn.constants.desktop',
        value: 'Desktop'
    },
    {
        label: 'msn.constants.tablet',
        value: 'Tablet'
    },
    {
        label: 'msn.constants.xbox',
        value: 'Xbox'
    }
];
// 系统list
export const osList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.android',
        value: 'Android'
    },
    {
        label: 'msn.constants.ios',
        value: 'iOS'
    },
    {
        label: 'msn.constants.macOs',
        value: 'Mac OS'
    },
    {
        label: 'msn.constants.windows',
        value: 'Windows'
    },
    {
        label: 'msn.constants.linux',
        value: 'Linux'
    }
];
// 浏览器list
export const browserList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.chrome',
        value: 'Chrome'
    },
    {
        label: 'msn.constants.safari',
        value: 'Safari'
    },
    {
        label: 'msn.constants.ie',
        value: 'Internet Explorer'
    },
    // {
    //     label: 'msn.constants.samsung',
    //     value: 'Samsung'
    // },
    {
        label: 'msn.constants.firefox',
        value: 'Firefox'
    },
    // {
    //     label: 'msn.constants.uc',
    //     value: 'UC Browser'
    // },
    // {
    //     label: 'msn.constants.inApp',
    //     value: 'InApp'
    // },
    {
        label: 'msn.constants.edge',
        value: 'Edge'
    }
];
// 尺寸汇总
export const SIZE_COLLECTION = [
    '622x368', '207x311',
    '311x333', '300x157', '300x175', '100x75', '90x90', '128x72'
];
// 速度列表
export const speedList = [
    {
        label: 'msn.constants.accelerate',
        value: '0'
    },
    {
        label: 'msn.constants.standard',
        value: '1'
    }
];
// 性别
export const genderList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.male',
        value: 'Male'
    },
    {
        label: 'msn.constants.female',
        value: 'Female'
    }
];
// 运营商
export const carrierList = [
    {
        label: 'msn.constants.all',
        value: 'All'
    },
    {
        label: 'msn.constants.docomo',
        value: 'Docomo'
    },
    {
        label: 'msn.constants.au',
        value: 'AU'
    },
    {
        label: 'msn.constants.softbank',
        value: 'Softbank'
    }
];
/**
 * @const 运营平台公共const
 */

// 运营配置黑白名单类型
export const blackWhiteType = [
    {
        label: 'msn.constants.white',
        value: '0'
    }
];

// 运营控制台POSTBACK_API
export const POSTBACK_API = 'https://sync.mediago.io/api/bidder/postback?trackingid='
    // eslint-disable-next-line
    + '${TRACKING_ID}&adid=${AD_ID}&conversiontype='
    // eslint-disable-next-line
    + '${CONVERSION_TYPE}&conversionprice=${CONVERSION_PRICE}';

// 是否展示对比基准日期
export const campareKeys = ['spend', 'lastSpend', 'compareRate'];


// 优化类型 tofo
export const optimizationTypeList = [
    {
        label: 'msn.dashboard.cvViewContent',
        value: '1'
    },
    {
        label: 'msn.dashboard.cvAppInstall',
        value: '2'
    },
    {
        label: 'msn.dashboard.cvCompleteRegistration',
        value: '3'
    },
    {
        label: 'msn.dashboard.cvAddToCar',
        value: '4'
    },
    {
        label: 'msn.dashboard.cvAddPaymentInfo',
        value: '5'
    },
    {
        label: 'msn.dashboard.cvSearch',
        value: '6'
    },
    {
        label: 'msn.dashboard.cvStartCheckout',
        value: '7'
    },
    {
        label: 'msn.dashboard.cvPurchase',
        value: '8'
    },
    {
        label: 'msn.dashboard.cvAddToWishlist',
        value: '9'
    },
    {
        label: 'msn.dashboard.cvLead',
        value: '10'
    }
];

// 审核状态
export const auditStatusList = [
    {
        label: 'msn.audit.inReview',
        value: 2
    },
    {
        label: 'msn.audit.approved',
        value: 1
    },
    {
        label: 'msn.audit.rejected',
        value: 0
    },
     {
        label: 'msn.audit.abnormal',
        value: 3
    }
];
// 审核状态
export const assetAuditStatusList = [
    {
        label: 'msn.audit.inReview',
        value: 2
    },
    {
        label: 'msn.audit.approved',
        value: 1
    },
    {
        label: 'msn.audit.rejected',
        value: 0
    },
    {
        label: 'msn.audit.limited',
        value: 3
    }
];
// 不支持等于选中天的过滤
export const dateRelationMap = [
    {
        label: 'msn.constants.earlier',
        value: 'lt'
    },
    {
        label: 'msn.constants.later',
        value: 'gt'
    }
];
// 服务列表
export const serviceList = [
    {
        label: 'msn.serviceRecord.tracker',
        value: '0'
    },
    {
        label: 'msn.serviceRecord.deliveryStatus',
        value: '2'
    },
    {
        label: 'msn.serviceRecord.meetingCommunication',
        value: '3'
    },
    {
        label: 'msn.serviceRecord.changeOfManager',
        value: '1'
    },
    {
        label: 'msn.serviceRecord.other',
        value: '4'
    }
];

// 记录类型
export const recordType = [
    {
        label: 'msn.serviceRecord.deliveryStatus',
        value: '2'
    },
    {
        label: 'msn.serviceRecord.meetingCommunication',
        value: '3'
    },
    {
        label: 'msn.serviceRecord.other',
        value: '4'
    }
];

export const daypartingTimezoneOptions = [
    { value: 'UTC-12', label: 'msn.create.daypartingTimezone.utc_12' },
    { value: 'UTC-11', label: 'msn.create.daypartingTimezone.utc_11' },
    { value: 'UTC-10', label: 'msn.create.daypartingTimezone.utc_10' },
    { value: 'UTC-9', label: 'msn.create.daypartingTimezone.utc_9' },
    { value: 'UTC-8', label: 'msn.create.daypartingTimezone.utc_8' },
    { value: 'UTC-7', label: 'msn.create.daypartingTimezone.utc_7' },
    { value: 'UTC-6', label: 'msn.create.daypartingTimezone.utc_6' },
    { value: 'EST', label: 'msn.create.daypartingTimezone.utc_5' },
    { value: 'UTC-4', label: 'msn.create.daypartingTimezone.utc_4' },
    { value: 'UTC-3', label: 'msn.create.daypartingTimezone.utc_3' },
    { value: 'UTC-2', label: 'msn.create.daypartingTimezone.utc_2' },
    { value: 'UTC-1', label: 'msn.create.daypartingTimezone.utc_1' },
    { value: 'UTC+0', label: 'msn.create.daypartingTimezone.utc' },
    { value: 'UTC+1', label: 'msn.create.daypartingTimezone.utc1' },
    { value: 'UTC+2', label: 'msn.create.daypartingTimezone.utc2' },
    { value: 'UTC+3', label: 'msn.create.daypartingTimezone.utc3' },
    { value: 'UTC+4', label: 'msn.create.daypartingTimezone.utc4' },
    { value: 'UTC+5', label: 'msn.create.daypartingTimezone.utc5' },
    { value: 'UTC+6', label: 'msn.create.daypartingTimezone.utc6' },
    { value: 'UTC+7', label: 'msn.create.daypartingTimezone.utc7' },
    { value: 'UTC+8', label: 'msn.create.daypartingTimezone.utc8' },
    { value: 'UTC+9', label: 'msn.create.daypartingTimezone.utc9' },
    { value: 'UTC+10', label: 'msn.create.daypartingTimezone.utc10' },
    { value: 'UTC+11', label: 'msn.create.daypartingTimezone.utc11' },
    { value: 'UTC+12', label: 'msn.create.daypartingTimezone.utc12' },
    { value: 'UTC+13', label: 'msn.create.daypartingTimezone.utc13' },
    { value: 'UTC+14', label: 'msn.create.daypartingTimezone.utc14' }
];
