/*
 * @file media.js
 * @author liushengxgi
 * @date 2020-09-02 17:56:22
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-02 17:56:30
 */

const BASE_URL = 'media/api/';
export default {
	// ssp site流量过滤
	getSspSiteFilterList: `${BASE_URL}publisherManager/sspfilter/list`, // list
	addSspSiteFilter: `${BASE_URL}publisherManager/sspfilter/save`, // 新增
	getSspSiteFilterDetail: `${BASE_URL}publisherManager/sspfilter/detail`, // 详情
	deleteSspSiteFilter: `${BASE_URL}publisherManager/sspfilter`, // 删除
	// account site流量过滤
	getAccountSiteFilterList: `${BASE_URL}publisherManager/accountsitefilter/list`, // list
	addAccountSiteFilter: `${BASE_URL}publisherManager/accountsitefilter/save`, // 新增
	deleteAccountSiteFilter: `${BASE_URL}publisherManager/accountsitefilter`, // 删除
	getAccountSiteFilterDetail: `${BASE_URL}publisherManager/accountsitefilter/detail`, // 详情 / 下载
	getAccountSiteFilterSiteList: `${BASE_URL}publisherManager/accountsitefilter/sitename`, // 获取已存在site
	getSiteListByPlatform: `${BASE_URL}publisherManager/site/getSiteListByPlatform`, // 获取对应平台的site
	// 素材四元素publisher site流量过滤
	actionAssetSitefilter: `${BASE_URL}publisherManager/assetsitefilter`, // list / 新增 / 删除
	getAssetSitefilterSitenameList: `${BASE_URL}publisherManager/assetsitefilter/sitename`, // 查询已存在site
	getAssetSitefilterDetail: `${BASE_URL}publisherManager/assetsitefilter/detail`, // 详情
	getAssetSitefilterCost: `${BASE_URL}publisherManager/assetsitefilter/cost`, // 查询花费
	getAssetSitefilterDownloadCost: `${BASE_URL}publisherManager/assetsitefilter/downloadcost`, // 下载花费
	// D_SSP level 黑白名單
	getDAccountBWList: `${BASE_URL}publisherManager/dBlackWhiteConfig/getAccountBWList`, // list
	getDiscoverySspList: `${BASE_URL}publisherManager/dBlackWhiteConfig/getDiscoverySspList`, // DiscoverySspList
	addDiscoverySsp: `${BASE_URL}publisherManager/dBlackWhiteConfig/addAccountSspOnlyNot`, // 新增
	updateDiscoverySsp: `${BASE_URL}publisherManager/blackWhiteConfig/updateOneAccountSspOnlyNot`, // 编辑
	getDiscoverySspOpLog: `${BASE_URL}publisherManager/blackWhiteConfig/getOpLog`, // 新增

	// dashboard
	mediaDashboardList: `${BASE_URL}publisherManager/dashboard`, // dashboard list
	mediaDashboardSummary: `${BASE_URL}publisherManager/summary`, // dashboard summary
	mediaDashboardExport: `${BASE_URL}publisherManager/csv`, // dashboard 表格导出

	// login
	mediaGetMenu: `${BASE_URL}publisherManager/menu`, // get menu

	// editorial tool
	// domain block
	mediaDomainList: `${BASE_URL}publisherManager/domainList`, // 获取domain 列表
	mediaDomainDel: `${BASE_URL}publisherManager/delDomain`, // 删除当前domain
	mediaDomainAdd: `${BASE_URL}publisherManager/addDomain`, // 添加domain屏蔽

	// authorization
	// user
	mediaUserList: `${BASE_URL}publisherManager/getUserList`, // 用户列表
	mediaUpdateUserStatus: `${BASE_URL}publisherManager/updateUserStatus`, // 用户状态变更
	mediaAddUser: `${BASE_URL}publisherManager/addUser`, // 新增用户
	mediaUpdateUser: `${BASE_URL}publisherManager/updateUser`, // 更新用户信息
	mediaGetUserInfo: `${BASE_URL}publisherManager/getUserInfo`, // 更新用户信息
	mediaGetRoleSelect: `${BASE_URL}publisherManager/getRoleSelect`, // 获取角色选项

	// account
	mediaGetSspList: `${BASE_URL}publisherManager/getSspList`, // 获取SSP列表
	mediaAddAccount: `${BASE_URL}publisherManager/addAccount`, // 新增账户
	mediaGetAccountList: `${BASE_URL}publisherManager/getAccountList`, // 获取账户列表
	mediaGetAccountInfo: `${BASE_URL}publisherManager/getAccountInfo`, // 获取账户信息
	mediaUpdateAccount: `${BASE_URL}publisherManager/updateAccount`, // 编辑更新账户信息
	mediaUpdateAccountStatus: `${BASE_URL}publisherManager/updateAccountStatus`, // 更新账户状态信息
	mediaGetAccountSelect: `${BASE_URL}publisherManager/getAccountSelect`, // 获取账户选项列表
	getCompanySelect: `${BASE_URL}publisherManager/getCompanySelect`, // 获取公司下拉列表
	companyAction: `${BASE_URL}publisherManager/companyAction`, // 创建公司
	getInnerUserList: `${BASE_URL}publisherManager/getInnerUserList`, // 获取cm或者bd
	getAccountInfo: `${BASE_URL}publisherManager/getAccountInfo`, // 获取账户信息
	downloadAccountList: `${BASE_URL}publisherManager/downloadAccountList`, // 处理导出
	// role
	mediaGetRoleList: `${BASE_URL}publisherManager/getRoleList`, // 获取角色列表
	mediaRoleAction: `${BASE_URL}publisherManager/addRole`, // 获取角色列表

	// 媒体定向
	mediaAudienceList: `${BASE_URL}publisherManager/getAudienceList`, // 获取媒体定向列表
	mediaAudienceAdd: `${BASE_URL}publisherManager/addAudience`, // 添加媒体定向
	mediaUpdateAudience: `${BASE_URL}publisherManager/updateAudience`, // 更新媒体定向
	mediaGetAccountSelectWrite: `${BASE_URL}publisherManager/getAccountSelectWrite`, // 获取当前用户的可写账户

	// 敏感词管理
	mediaGetSensitiveTagList: `${BASE_URL}publisherManager/getSensitiveTagList`, // 获取敏感词列表
	mediaAddSensitiveTag: `${BASE_URL}publisherManager/addSensitiveTag`, // 新增敏感词
	mediaGetSensitiveListg: `${BASE_URL}publisherManager/getSensitiveList`, // 获取敏感分类配置列表
	mediaAddSensitive: `${BASE_URL}publisherManager/addSensitive`, // 添加敏感分类配置
	mediaUpdateSensitive: `${BASE_URL}publisherManager/updateSensitive`, // 修改敏感分类配置
	mediaGetSensitiveTagSelect: `${BASE_URL}publisherManager/getSensitiveTagSelect`, // 获取敏感选项

	// ADX
	mediaAdxAddSsp: `${BASE_URL}publisherManager/addSsp`, // 新增 ADX
	mediaAdxAdxList: `${BASE_URL}publisherManager/adxList`, // 获取 ADX列表
	mediaAdxModifyAdx: `${BASE_URL}publisherManager/sspStatus`, // 改变状态
	mediaAdxAddWhiteList: `${BASE_URL}publisherManager/addWhiteList`, // 新增ssp
	setCampaignSspEffectForD: `${BASE_URL}publisherManager/setCampaignSspEffectForD`, // D平台campaign上下单
	getMDisplaySspList: `${BASE_URL}publisherManager/mDisplaySsp/ssplist`, // display ssp自动上单配置--获取ssp列表
	getMDisplaySspLogList: `${BASE_URL}publisherManager/mDisplaySsp/log`, // display ssp自动上单配置--获取操作日志列表
	mDisplaySspDownload: `${BASE_URL}publisherManager/mDisplaySsp/download`, // display ssp自动上单配置--导出配置list
	mDisplaySspSave: `${BASE_URL}publisherManager/mDisplaySsp`, // display ssp自动上单配置--保存配置
	validSspList: `${BASE_URL}publisherManager/validSspList`, // 可操作ssp

	// site
	getSiteList: `${BASE_URL}publisherManager/site/list`,
	getSiteDetail: `${BASE_URL}publisherManager/site/detail`,
	siteEdit: `${BASE_URL}publisherManager/site/edit`,
	// 根据媒体账户获取站点
	getSiteListByAccount: `${BASE_URL}publisherManager/site/getSiteListByAccount`,
	// 获取媒体账户列表
	getAccountSelectByPage: `${BASE_URL}publisherManager/getAccountSelectByPage`,
	// 通过ssp获取site列表
	getSiteListBySsp: `${BASE_URL}publisherManager/site/getSiteListBySsp`,
	// 账户列表
	// getAccountSelect: `${BASE_URL}publisherManager/getAccountSelect`,
	// media taglist
	getTagList: `${BASE_URL}publisherManager/tag/list`,
	AddTag: `${BASE_URL}publisherManager/tag/action`,

	// 审核标签列表下拉框(GET) 接口地址：tag/getAuditTypeList
	getAuditTypeList: `${BASE_URL}publisherManager/tag/getAuditTypeList`,
	// 审核标签类型新增/编辑(POST)请求地址：tag/auditTypeAction
	auditTypeAction: `${BASE_URL}publisherManager/tag/auditTypeAction`,
	// 获取指定标签下站点(GET) tag/getSiteListByTag 参数tagId
	getSiteListByTag: `${BASE_URL}publisherManager/tag/getSiteListByTag`,
	// 审核标签列表下拉框
	getAuditTypeListSelect: `${BASE_URL}publisherManager/tag/getAuditTypeListSelect`,
	filterEligibleSites: `${BASE_URL}publisherManager/site/filterEligibleSites`,
	getSiteUpdateOperateLog: `${BASE_URL}publisherManager/tag/getOperateLog`,
	deleteTag: `${BASE_URL}publisherManager/tag/delete`,
	deleteAuditType: `${BASE_URL}publisherManager/tag/deleteAuditType`,
	// 分类下的标签
	getTagListByAuditType: `${BASE_URL}publisherManager/tag/getTagListByAuditType`,
	// 获取全部标签
	getAllTagByType: `${BASE_URL}publisherManager/tag/getAllTagByType`,
	// 获取详情
	tagDetail: `${BASE_URL}publisherManager/tag/detail`,
	// 查询account only和not接口
	getAccountBWList: `${BASE_URL}publisherManager/blackWhiteConfig/getAccountBWList`,
	// 查询mediago SSP列表
	getMediagoSspList: `${BASE_URL}publisherManager/blackWhiteConfig/getMediagoSspList`,
	// 账户黑白名单配置log查询接口
	getOpLog: `${BASE_URL}publisherManager/blackWhiteConfig/getOpLog`,
	// 批量增加account only 和not接口
	updateAccountSspOnlyNot: `${BASE_URL}publisherManager/blackWhiteConfig/updateAccountSspOnlyNot`,
	// 归档
    updateOneAccountSspOnlyNot: `${BASE_URL}publisherManager/blackWhiteConfig/updateOneAccountSspOnlyNot`,
    // 单次拒审下载文档
	downloadFile: `${BASE_URL}publisherManager/auditRejectOnce/downloadFile`,

	// 账户seller id 配置列表
	getSellerIdList: `${BASE_URL}publisherManager/sellerBlackWhiteConfig/getAccountList`,
	// 获取单个账户配置
	getSingleSellerId: `${BASE_URL}publisherManager/sellerBlackWhiteConfig/getAccountConfig`,
	// 获取账户log
	getSellerLog: `${BASE_URL}publisherManager/sellerBlackWhiteConfig/getAccountConfigLog`,
	// 批量新增sellerId 黑白名单
	batchAddSellerId: `${BASE_URL}publisherManager/sellerBlackWhiteConfig/batchAddSellerId`,
	// 但账户编辑seller id 黑白名单
	editSingleConfig: `${BASE_URL}publisherManager/sellerBlackWhiteConfig/editAccountConfig`,

	// 获取巡航拒审列表
	getCruiseRejectBlackList: `${BASE_URL}publisherManager/cruiseRejectBlackList/getCruiseRejectBlackList`,
	// 获取单个creative id
	getCruiseRejectBlackListDetail: `${BASE_URL}publisherManager/cruiseRejectBlackList/getCruiseRejectBlackListDetail`,
	// creative id 花费列表
	getCreativeIdSpend: `${BASE_URL}publisherManager/auditRejectOnce/getAuditRejectSpend`,
	// 查询creative id log
	getCreativeIdLog: `${BASE_URL}publisherManager/creativeIdBlackList/getCreativeIdLog`,
	// 批量新增creative id接口
	batchAddCreativeId: `${BASE_URL}publisherManager/creativeIdBlackList/batchAddCreativeIdConfig`,
	// 删除单个creative id
	delSingleCreativeId: `${BASE_URL}publisherManager/cruiseRejectBlackList/delCruiseRejectBlackList`,
    // 下载详细内容
    downloadAuditRejectInfo: `${BASE_URL}publisherManager/cruiseRejectBlackList/downloadAuditRejectInfo`,
	// 新增拒审
	addCruiseRejectBlackList: `${BASE_URL}publisherManager/cruiseRejectBlackList/addCruiseRejectBlackList`,
    getCreativeRiskControlList: `${BASE_URL}publisherManager/creativeRiskControl/getCreativeRiskControlList`,
    insertCreativeRiskControl: `${BASE_URL}publisherManager/creativeRiskControl/insert`,
    updateCreativeRiskControl: `${BASE_URL}publisherManager/creativeRiskControl/update`,
    deleteCreativeRiskControl: `${BASE_URL}publisherManager/creativeRiskControl/deleteCreativeRiskControlInfo`,
    getCreativeRiskControlLog: `${BASE_URL}publisherManager/creativeRiskControl/getCreativeRiskControlLog`,

    // 单次拒审api
	// 查询单次拒审log接口
	getAuditRejectLogList: `${BASE_URL}publisherManager/auditRejectOnce/getAuditRejectLogList`,
	// 单次拒审确认接口
	startAuditReject: `${BASE_URL}publisherManager/auditRejectOnce/startAuditReject`,
	// 单次log查看接口
	getAuditRejectLogDetail: `${BASE_URL}publisherManager/auditRejectOnce/getAuditRejectLogDetail`,
	// creative搜索列表
	getCreativeSource: `${BASE_URL}publisherManager/entityRiskControl/getCreativeSource`,
	// 终止单次拒审接口
	stopAuditReject: `${BASE_URL}publisherManager/auditRejectOnce/stopAuditReject`,
	getEntityRiskControlList: `${BASE_URL}publisherManager/entityRiskControl/getEntityRiskControlList`, // 获取图片素材风控列表
	entityRiskControlInsert: `${BASE_URL}publisherManager/entityRiskControl/insert`, // 新增素材风控
	getEntityNameListByType: `${BASE_URL}publisherManager/entityRiskControl/getEntityNameListByType`, // 获取个维度下拉列表
	updateEntityRiskControl: `${BASE_URL}publisherManager/entityRiskControl/update`, // 更新素材风控
	getEntityRiskControlLog: `${BASE_URL}publisherManager/entityRiskControl/getEntityRiskControlLog`, // 获取风控配置操作日志
	deleteEntityRiskControlInfo: `${BASE_URL}publisherManager/entityRiskControl/deleteEntityRiskControlInfo`, // 删除风控配置

	getXandrLanguage: `${BASE_URL}publisherManager/xandrAudit/getXandrLanguage`, // 语言下拉框getlist
	exportAudit: `${BASE_URL}publisherManager/xandrAudit/export`, // 导出
	getXandrAuditList: `${BASE_URL}publisherManager/xandrAudit/getXandrAuditList`, // 获取xandr审核展示列表
	xandrResubmitAssetQuery: `${BASE_URL}publisherManager/xandrAudit/xandrResubmitAssetQuery`, // 重新送审Asset查询
	xandrResubmitDetail: `${BASE_URL}publisherManager/xandrAudit/xandrResubmitDetail`, // 重新送审log
	xandrTokenBlockDetail: `${BASE_URL}publisherManager/xandrAudit/xandrTokenBlockDetail`, // 小扳手按钮-Seller ID TokenBlock详情
	xandrResubmitAudit: `${BASE_URL}publisherManager/xandrAudit/xandrResubmitAudit`, // 重新送审提交按钮
    getEntityRiskControlExistList: `${BASE_URL}publisherManager/entityRiskControl/getEntityRiskControlExistList`, // 账户层级下放控制检查已存的creative数
    // 自动送审
	autoAuditSubmitConfig: `${BASE_URL}publisherManager/xandrAudit/autoSubmit/config`, // 获取 get 和 提交 post 自动重新送审配置
	autoAuditConfiglog: `${BASE_URL}publisherManager/xandrAudit/autoSubmit/config/log`, // 自动重新送审日志log
	autoAuditBaseInfo: `${BASE_URL}publisherManager/xandrAudit/autoSubmit/baseInfo` // 自动重新送审获取account
};