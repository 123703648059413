/**
 * @file 结构文件
 * @author  fangsimin
 * @date    2018-12-21 10:46:18
 * @last Modified by    fangsimin
 * @last Modified time  2020-03-01 13:04:42
 */

<template>
    <el-container
        v-loading="loading"
        :class="!showMenu ? 'no-permission' : ''"
        element-loading-spinner="el-icon-loading"
    >
        <el-aside
            class="aside"
            :style="{width: asideStatus ? '65px' : '240px'}"
        >
            <div class="menu-box">
                <div
                    class="logo logo-bg"
                    :class="{'m-logo': asideStatus}"
                ></div>
                <aside-menu
                    :data="menuData"
                    :base="base"
                    :is-collapse="asideStatus"
                />
            </div>
            <!-- <div class="lang-container">
                <span
                    :class="curLang === 'cn' ? 'active' : ''"
                    @click="handleCommand('zh_CN')"
                    >{{ asideStatus ? "中" : "中文" }}</span
                >/
                <span
                    :class="curLang === 'en' ? 'active' : ''"
                    @click="handleCommand('en_US')"
                    >{{ asideStatus ? "En" : "English" }}</span
                >
            </div> -->
            <div class="aside-ctrl" @click="handleAsideCtrlClick">
                <div
                    class="aside-ctrl-icon"
                    :class="{'aside-ctrl-icon_reversed': asideStatus}"
                ></div>
            </div>
        </el-aside>
        <el-container>
            <el-header class="head-ele" style="height: 56px;">
                <div class="header-title">
                    <el-breadcrumb
                        v-if="!currentPath.includes('apply')"
                        class="breadcrumb"
                        separator-class="el-icon-arrow-right"
                    >
                        <template v-for="(list, index) in currentPath">
                            <el-breadcrumb-item
                                v-if="BREADCRUMBLINKS.includes(list)"
                                :key="index"
                                :to="{path: PATHS[list]}"
                            >
                                {{
                                    renderBreadCrumbTitle(list)
                                }}
                            </el-breadcrumb-item>
                            <el-breadcrumb-item v-else :key="index">
                                {{
                                    renderBreadCrumbTitle(list)
                                }}
                            </el-breadcrumb-item>
                        </template>
                    </el-breadcrumb>
                    <div v-else class="login-logo np-logo"></div>
                </div>
                <!-- 有用户信息时，才展示 -->
                <div v-if="userName || userId" class="topBar">
                    <!-- <div class="lang-container_noFixd lang-container">
                        <span
                            :class="curLang === 'cn' ? 'active' : ''"
                            @click="handleCommand('zh_CN')"
                            >中文</span
                        >/
                        <span
                            :class="curLang === 'en' ? 'active' : ''"
                            @click="handleCommand('en_US')"
                            >English</span
                        >
                    </div> -->
                    <div class="userWrap">
                        <div class="userName">
                            <div class="user-container">
                                <!-- <img
                                    v-if="+role === 1"
                                    class="msn-role margin-right-5"
                                    height="15px"
                                    :src="
                                        require(`../msn/assets/images/icon/super-${curLang}.png`)
                                    "
                                /> -->
                                {{ userName || userId }}
                            </div>
                        </div>
                        <el-tooltip
                            popper-class="msn-tool-tip"
                            effect="gray"
                            :content="$t('message.button.logout')"
                            placement="bottom-start"
                        >
                            <div
                                :class="
                                    `msn-fa msn-fa-${
                                        showMenu ? 'logout' : 'logout-w'
                                    } logout-btn`
                                "
                                @click="loginOut"
                            ></div>
                        </el-tooltip>
                    </div>
                </div>
                <div v-else class="topBar">
                    <el-tooltip
                        popper-class="msn-tool-tip"
                        effect="gray"
                        :content="$t('message.button.login')"
                        placement="bottom-start"
                    >
                        <a
                            :href="PATHS['mediaLogin']"
                            class="item-link msn-fa msn-fa-login"
                        ></a>
                    </el-tooltip>
                </div>
            </el-header>
            <el-main class="msn-main" style="padding-top: 0;">
                <keep-alive :include="keepAliveComp">
                    <router-view/>
                </keep-alive>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
// 左边菜单
import pathConfig from './constants/pathConfig';
import asideMenu from '@/modules/msn/components/Menu';
import tool from '../msn/common/tool';
import { mapMutations, mapGetters } from 'vuex';
import api from '@/constants/api';
// import { HELP_CENTER } from '@/constants/helpCenterPath';
import constants from '@/constants';
import { MODULE_PLATFORM } from '@/modules/media/constants';
const PATHS = constants.path;
const BREADCRUMBLINKS = constants.breadcrumbLinks;

const moduleName = MODULE_PLATFORM;
// const queryPS = 5 * 60 * 1000; // 获取当前账户信息的轮询间隔

export default {
    name: 'MeidaApp',
    components: {
        asideMenu
    },
    data() {
        return {
            base: moduleName,
            pathConfig: pathConfig,
            loading: false,
            PATHS,
            BREADCRUMBLINKS,
            asideStatus: false // 侧边栏收缩状态 true->折叠 false->展开
        };
    },
    computed: {
        ...mapGetters('common', {
            curLang: 'getLang'
        }),
        ...mapGetters('media', {
            userName: 'getUserName',
            userId: 'getUserId',
            role: 'getRole',
            showFeedback: 'getShowFeedback',
            keepAliveComp: 'getKeepAliveComp',
            menu: 'getMenu'
        }),
        // 没权限则不展示左侧菜单
        showMenu() {
            const routePath = this.$route && this.$route.path;
            const path = routePath && routePath.split('/').slice(-1)[0];
            return path !== 'apply';
        },
        currentPath() {
            let path = this.$route.path;
            return path.split('/').slice(2);
        },
        menuData() {
            const path = this.pathConfig['menu'];
            let res = [];
            path.forEach(p => {
                let curMenu = {};
                if (tool.includePermission(p.path, 'media')) {
                    curMenu = p;
                    if (p.children && p.children.length > 0) {
                        curMenu.children = p.children.filter(pc =>
                            tool.includePermission(pc.path, 'media')
                        );
                    }
                    res.push(curMenu);
                }
            });
            return res;
        }
    },
    mounted() {
        this.gaMedia();
    },
    methods: {
        ...mapMutations('common', {
            switchLang: 'switchLang'
        }),
        handleCommand(command) {
            const showConfirm = this.currentPath.includes('campaign');
            const permission = this.currentPath.includes('apply') && !this.showFeedback;
            if (showConfirm) {
                this.$confirm(
                    this.$t('msn.validations.switchLang'),
                    this.$t('msn.infos.info'),
                    {
                        type: 'warning',
                        customClass: 'msn-message-box',
                        showClose: false,
                        cancelButtonText: this.$t('msn.button.cancel'),
                        confirmButtonText: this.$t('msn.button.confirm'),
                        cancelButtonClass: 'msn-cancel-button',
                        confirmButtonClass: 'msn-confirm-button'
                    }
                )
                    .then(_ => {
                        localStorage.setItem(
                            'lang',
                            command.startsWith('zh') ? 'cn' : 'en'
                        );
                        location.reload();
                    })
                    .catch(_ => {});
            } else if (permission) {
                // 注册页面不进行确认，直接刷新
                localStorage.setItem(
                    'lang',
                    command.startsWith('zh') ? 'cn' : 'en'
                );
                location.reload();
            } else {
                this.switchLang(command);
            }
        },
        gaMedia() {
            tool.gaMedia({
                action: 'Log_In',
                category: 'Log_In'
            }, {
                info: ['userName', 'accountListName'],
                role: ['publisher']
            });
        },
        loginOut() {
            this.loading = true;
            this.$request({
                method: 'get',
                url: api.logout,
                success(res) {
                    this.loading = false;
                    this.$message.success(
                        this.$t('msn.requestMsg.logoutSuccess')
                    );
                    location.href = PATHS['mediaLogin'];
                },
                error() {
                    this.loading = false;
                    this.$message.error(this.$t('msn.requestMsg.logoutFailed'));
                }
            });
        },
        renderBreadCrumbTitle(title) {
            return this.$t(`media.menuData.${title}Title`);
        },
        // 处理收缩侧边栏
        handleAsideCtrlClick() {
            this.asideStatus = !this.asideStatus;
            let timeOut = setTimeout(() => {
                // window.onresize && window.onresize();
                let event = document.createEvent('HTMLEvents');
                event.initEvent('resize', true, true);
                window.dispatchEvent(event);
                clearTimeout(timeOut);
            }, 300);
        }
    },
    watch: {
        curLang: {
            immediate: true,
            handler(val) {
                this.$i18n.locale = val;
            }
        }
    }
};
</script>

<style lang="scss">
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
    textarea,
    input,
    button,
    select {
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }
    #app {
        height: 100%;
    }
    ::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }

    // 菜单按钮收缩
    .menu-box {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar-track-piece {
            background: #d3dce6;
        }
        &::-webkit-scrollbar-corner {
            background: rgba(0, 0, 0, 0);
        }

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #99a9bf;
            border-radius: 20px;
        }
    }
</style>

<style lang="scss" scoped>
.el-header {
    background-color: #fff;
    color: #53575b;
    line-height: 50px;
    font-size: 12px;
    height: 56px;
    display: flex;
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, 0.16);
    z-index: 1;
}

.header-title {
    flex: 1;
    text-align: left;
    font-size: 14px;
    color: #2dd1ac;
    font-weight: 700;
    display: flex;
    align-items: center;
    .np-logo {
        margin-left: 10px;
    }
}

.head-ele .breadcrumb {
    flex: 1;
    line-height: 50px;
    /deep/ {
        .el-breadcrumb__item:last-child .el-breadcrumb__inner,
        .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
        .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
        .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
        .el-breadcrumb__item:first-child .el-breadcrumb__inner,
        .el-breadcrumb__item:first-child .el-breadcrumb__inner:hover,
        .el-breadcrumb__item:first-child .el-breadcrumb__inner a,
        .el-breadcrumb__item:first-child .el-breadcrumb__inner a:hover {
            font-weight: 700 !important;
            color: #2dd1ac !important;
        }
    }
}

.el-aside {
    color: #333;
    background: #4e546c;
    box-shadow: 0 0 12px 0 rgba(113, 113, 113, 0.5);
    font-size: 14px;
    height: 100%; // calc(100% - 50px);
    overflow: initial;
}
.el-container {
    height: 100%;
    position: relative;
    background: #eef4f9;
}
.el-main {
    padding-top: 0;
}
.el-dropdown-link {
    cursor: pointer;
    i {
        transition: all 0.2s ease-in-out;
        &.msn-fa-account-arrow-down {
            vertical-align: middle;
        }
    }
}
.el-dropdown-link:hover {
    // color: #409eff;
    color: inherit !important;
}

.logo {
    height: 140px;
    font-size: 40px;
    color: #ffffff;
    letter-spacing: 2px;
    line-height: 140px;
}

.topBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #53575b;
    font-weight: 500;
    .item-link {
        font-size: 20px;
        text-decoration: none;
    }
}
.userWrap {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.margin-right-5 {
    margin-right: 5px;
}
.userName {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #53575b;
    margin-right: 20px;
    line-height: 1.5;
    .user-container {
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .user-account {
        display: flex;
        align-items: center;
        .account-balance-container {
            margin-right: 8px;
            display: flex;
            align-items: center;
            .msn-fa {
                margin-right: 3px;
                font-size: 15px;
            }
        }
    }
}
.dropdown-style {
    max-width: 275px;
    max-height: 360px;
    overflow: auto;
    text-align: left;
    padding: 5px 0;
    li {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        &:hover,
        &:focus {
            color: inherit !important;
            background-color: #eef4f9 !important;
        }
    }
    .account-name {
        display: inline-block;
        vertical-align: middle;
        width: 135px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 15px;
    }
    .balance-text {
        display: inline-block;
        text-align: right;
        font-size: 12px;
        max-width: 100px;
        i {
            transform: translateY(2px);
        }
        &.success-text {
            color: #53575b;
        }
        &.danger-text {
            color: #e92754;
        }
    }
}
.lang-container {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 0;
    bottom: -50px;
    z-index: 100;
    color: #fff;
    line-height: 12px;
    padding: 19px 0;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background: #525974;
    span {
        cursor: pointer;
        transition: color 0.5s;
    }
    .active {
        color: #2dd1ac;
    }
}
.logout-btn {
    font-size: 20px;
    cursor: pointer;
}
.lang-container_noFixd {
    display: none;
}
.no-permission {
    .el-aside {
        display: none;
    }
    .el-main {
        padding: 0;
        margin-top: -56px;
    }
    .el-header {
        z-index: 1;
        background: transparent;
        box-shadow: none;
        .el-dropdown,
        .el-dropdown-link,
        .userName {
            color: #fff !important;
            &:hover {
                color: #fff;
            }
        }
    }
    .lang-container {
        display: block;
        position: static;
        height: 25px;
        line-height: 25px;
        padding: 0 10px;
        width: auto;
        background: none;
        span {
            cursor: pointer;
            transition: color 0.5s;
        }
        .active {
            color: #3f93f5;
        }
    }
}
.aside-ctrl {
    position: absolute;
    background-image: url(../msn/assets/images/asideCtrlBg.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 95px;
    width: 12px;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &-icon {
        width: 0;
        height: 0;
        border-width: 6px 5px 6px 0px;
        border-color: transparent;
        border-right-color: #b3b7c8;
        border-style: solid;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -50%);
        &_reversed {
            transform: rotate(180deg);
            transform-origin: 25% 25%;
        }
    }
}
.aside {
    position: relative;
    transition: width 0.28s;
}
</style>
