/*
 * @file ko.js
 * @author liushengxgi
 * @date 2020-09-03 10:52:17
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-03 11:00:49
 */

export default {
    menuData: {
        dashboardTitle: '대쉬보드',
        editorialTitle: '편집툴',
        domainTitle: '도메인블락',
        authorizeTitle: '권한관리',
        roleTitle: '역할관리',
        accountTitle: '계정리스트',
        userTitle: '유저리스트',
        managementTitle: '매체리스트',
        audienceTitle: '광고 타깃 구성',
        sensitiveTagTitle: 'Sensitive Tag 리스트',
        sensitiveCfgTitle: 'Sensitive Tag 설정',
        adxTitle: 'SSP관리',
        discoveryBWconfigTitle: 'D_SSP level 黑白名單',
        mediaBWconfigTitle: 'Account-level media blacklist/whitelist',
        mediaBWconfig: 'Account-level media blacklist/whitelist',
        mediaSellerIdConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        cruiseRejectBlackListTitle: 'Cruise Rejection',
        assetSiteFilter: '素材四元素publisher site流量过滤',
        assetSiteFilterTitle: '素材四元素publisher site流量过滤',
        auditRejectOnceTitle: 'Single Rejection',
        creativeRiskTitle: 'Image Creative Risk Control',
        xandrAuditManagment: 'Brand Name Review Management',
        xandrAuditManagmentTitle: 'Brand Name Review Management',
        accountSiteFilter: '账户类型、公司账户屏蔽',
        accountSiteFilterTitle: '账户类型、公司账户屏蔽'
    },
    button: {
        addAccount: '계정추가',
        addUser: '유저추가',
        addRole: '역활추가',
        status: '상태',
        del: '삭제',
        bwList: 'Black and white list'
    },
    dashboard: {
        bwType: 'Blacklist & Whitelist Type',
        mediaSearchAcc: '계정으로 사용자 검색',
        rpm: 'RPM',
        vrpm: 'vRPM',
        totalRevenue: '총매출',
        matchedRequest: '일치한 광고 요청',
        returnAds: 'Return Ads',
        viewsWithAds: 'Views with Ads',
        clicks: 'Ad Clicks',
        withAds: '% with Ads',
        withVisibility: '% with Visibility',
        vctr: 'vCTR',
        rendersWithAds: '광고 렌더링',
        winRate: 'Win Rate',
        avgCPC: '평균CPC',
        accountName: '계정명',
        // Dimension
        dimensionDate: '날짜',
        dimensionAccount: '계정',
        dimensionSSP: 'SSP',
        dimensionSite: 'Site',
        payin: '결제'
    },
    editorial: {
        domain: {
            searchPlaceholder: '광고도메인/계정명을 검색',
            remove: '리스트에서 삭제',
            block: '블락도메인 추가'
        }
    },
    permission: {},
    management: {
        rejectAutoAudit: 'Rejected for Review and Under Review of Automatically Resubmitted',
        noLimit: 'All',
        yes: 'Yes',
        no: 'No',
        illegalCharacter1: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate ssp.',
        illegalCharacter2: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate campaign id.',
        illegalCharacter3: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate account id.',
        illegalCharacter4: 'The use of Chinese semicolon/Chinese comma/English comma separation is not supported. Please use English semicolon; to separate account id',
        illegalCharacter5: 'The use of Chinese semicolon/Chinese comma/English comma separation is not supported. Please use English semicolon; to separate Seller ID.',
        setWhite: 'Whitelist Setting',
        meidaWhiteList: 'Campaign Place or Orders in Batches',
        accountConfig: 'Account Place or Orders in Batches',
        sspTip1: ' is broad reach SSP and the operation is not supported.',
        sspTip2: ' is the SSP of D platform and the operation is not supported.',
        sspTip3: 'Parameter Error',
        actionDetails: 'Action Details',
        xandrAsset: 'Asset',
        detailTip: 'Detail',
        operate: 'Operate',
        reAuditTip: 'Manually Resubmit for Review',
        automaticResubmissionSetTitle: 'Automatic Resubmission Settings',
        reAssetIcon: 'When entering LP and clicking search, all unarchived assets using this Lp can be displayed from the drop-down list in the resubmit asset below.',
        reAssetTip: 'Resubmit for Review',
        tokenBlockDetail: 'Seller ID Token Block Detail',
        sellerIdText: 'The brand name has been blocked by some seller ID token in the media, click the button on the right side to check the detail',
        brandNameLanguageTip: 'Due to inconsistencies between the submission language and the media language, this brand name can\'t acquire msn traffic.',
        pendingReview: 'Pending Review',
        underReview: 'Under Review',
        reject: 'Reject',
        pass: 'Pass',
        auditFailure: 'Audit failure',
        reviewExpired: 'reviewExpired',
        brandNameReviewManagement: 'Brand Name Review Management',
        autoResubmissionSetTitle: 'Automatic Resubmission Settings',
        autoAuditAccountStatus: 'Automatically Resubmit Status',
        isSeller: 'Resubmission Condition Include Seller ID 280 Rejection',
        autoAuditAccountNum: 'Number of Automatic Re-submissions for Review',
        assetConditionSettings: 'Automatically Resubmit Asset Condition Settings',
        accountRange: 'Range of Account',
        campaignRange: 'Range of Campaign',
        assetRange: 'Asset Condition',
        all: 'All',
        oneDayTop: 'Top asset of this brand name at -1 day',
        anyAll: 'Any one of all',
        autoStatusOpt: 'Status：on/off；Number of Automatic Resubmissions：3；Range of Account：All；Range of Campaign：All；Asset Condition：Top asset of this brand name at -1 day',
        autoStatusTip: 'It is enabled by default. If it is closed manually, it will stop automatically resubmitting for review.',
        isSellerTip: 'The automatic resubmission condition defaults to rejection status, which does not include rejection status by seller ID 280. If you need to include this status, please select "Yes"',
        autoResubmissionNumTip: 'The number of times is a re-accumulation system. If you switch from 3 times to 5 times, the times will be recalculated.',
        accountAangeTip: 'The account displayed in the drop-down is the account to which the material corresponding to the Brand Name belongs.',
        campaignAangeTip: 'The Campaign displayed in the drop-down is the Campaign to which the material corresponding to the Brand Name belongs. If a specific account is selected in the above account range, it is the Campaign under the selected account.',
        assetAangeTip: 'The default is to submit for review based on the Top Asset income of the Asset corresponding to the Brand Name in the past day, excluding Assets that have been submitted for review and Assets that have been archived.',
        tokenBlock: 'Token Block',
        notTokenBlock: 'Not token block',
        sellerReason: 'Reason',
        reAsset: 'Resubmit Asset',
        xanderBrandName: 'Brand Name',
        auditStatus: 'Audit Status',
        xanderReason: 'Reason',
        reviewTime: 'Review Time',
        submissionTime: 'Submission Time',
        reviewLang: 'Language',
        typeSelect: 'Type',
        platform: 'Platform',
        languageUnequal: 'Inconsistent Submission Language',
        mediaLanguage: 'Media Language',
        type: 'Type',
        asset: 'Asset',
        lp: 'LP',
        searchLp: 'Enter LP to search Asset',
         addCreativeConfig: 'There are some creatives under this account that have the same dimension configuration, do they need to be overwritten and deleted?',
         creativeTip: 'When selecting account dimension control, you can choose summary control and decentralized  control; summary control means that the sum of accounts does not exceed the set upper limit; ecentralized control means that all account controls are applied to all creative id under it.',
        updateCreative: 'The {num} creatives under the account already have configurations of the same dimension, do you want to update?',
        deleteCreative: 'There are {num} creatives under this account that have been configured or are controlled by other accounts, do they need to be overwritten and deleted?',
        controlMethod: 'Control Method',
        sourceOfControl: 'Source of Control',
        summaryControl: 'summary control',
        decentralizedControl: 'decentralized control',
        itself: 'itself',
        creativePlaceholder: 'please use commas to separate',
        campaginSearch: 'Campaign ID',
        companyLevel: 'Company Level',
        accountLevel: 'Account Level',
        deleteDimension: 'After deletion, the condition restriction for this dimension will become invalid. Are you sure to delete?',
        companyName: 'Company Name',
        accountName: 'Account Name',
        accountId: 'Account ID',
        campaginId: 'Campaign ID',
        dimension: 'Dimension',
        audienceAdd: '광고 타깃 추가',
        sensitiveTagAdd: 'Sensitive Tag 추가',
        sensitiveCfgAdd: 'Sensitive Tag 설정 추가',
        // adx
        rejectedSources: 'Rejected Sources',
        modifyAdx: 'adx 변경',
        addWhiteList: '대량 화이트리스트 추가',
        setWhiteForD: 'Discovery媒体白名单配置',
        setDisplaySsp: 'Display SSP自动上单配置',
        addAdx: 'spp 추가',
        adxSspId: 'sspid',
        adxSspName: 'ssp name',
        adxToken: 'token',
        adxHMedia: '고급 미디어 여부',
        adxIp: '클라이언트 IP 사용 여부',
        adxGEOEdge: 'GEO Edge',
        adxAddSuccess: '작성 완료',
        adxModifySuccess: '변경 완료',
        timezone: '시간대',
        ssp: 'ssp',
        operateType: 'operateType',
        campaginName: '캠페인명',
        reason: 'Reason',
        creativeIdMsg: 'After deletion, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        rejectMsg: 'After termination, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        searchSpend: 'Search Spend',
        mediaRequire: 'media requirement',
        required: '{name} is required',
        dailyCost: 'Limit of Daily Spend',
        dailyImp: 'Limit of Daily Imp',
        assetImageMsg: 'After deletion, the conditional restrictions on the creative id will be invalid. Are you sure to delete the setting of this creative id?',
        dailyCostTip: 'The daily spend is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        dailyImpTip: 'The daily imp is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        countryTip: 'When multiple country are selected, they will be split for statistics, and the relationship between them and the selection in the dimension is N*N. For example, if you select the United States and the United Kingdom for the country, and account 1 is selected for the dimension, two pieces of data will appear on the page after submission.',

        hasCreativeId: 'The creative id already exists, please operate from the Edit entry in the action column',
        hasSppGroup: 'This SSP combination already exists, please edit it in the original combination',
        sitePlaceHolder: 'please use commas to separate',
        dailyCostValid: 'The daily cost must have no more than 2 decimal positions',
        element: 'Element',
        country: 'Country',
        language: 'Language',
        content: 'Content',
        rejectedReason: 'Rejection Reason',
        brandName: 'Brand name',
        keyword: 'Keyword',
        domainName: 'Domain',
        operator: 'Operator',
        reviewer: 'Reviewer',
        rejectAddSuccess: 'Add successfully',
        createTime: 'Create Time',
        completionTime: 'Completion Time',
        operatingTime: 'Operating Time',
        status: 'Status',
        doing: 'In execution, Click to terminate',
        stop: 'Terminated',
        done: 'Completed',
        download: 'Download',
        detail: 'detail',
        success: 'success',
        downloadToReject: 'Please download the details for a second confirmation of rejection',
        noDownload: 'Please download the details before confirming your rejection',
        isConfrimReject: 'Are you sure you want to reject them?',
        contentTitle: `【Example of Creative ID dimension content】
        URL：https://d2cli4kgl5uxre.cloudfront.net/ML/c5d69c5de0bbac6490bda9d103btestd.png
        Input：c5d69c5de0bbac6490bda9d103btestd
        （Between https://d2cli4kgl5uxre.cloudfront.net/ML/ and .png）
        【Example of Brand Name dimension content】
        Brand Name：Test Brand
        Input：Test Brand
        【Example of keyword dimension content】
        Headline：Be careful if you have these illegal words around you!
        「Case 1」Input：illegal words
        Match：Be careful if you have these illegal words around you!;;These illegal words will affect your life
        「Case 2」Input：Be careful if you have these illegal words around you!
        Match：Be careful if you have these illegal words around you!
        【Example of Domain dimension content】
        Landing Page：https://test.cloudfront.net/
        Input：test.cloudfront.net
        （Between https:// and /）
        `,
        rejectTitle: '* Operation records before April 17, 2023, are displayed as Baidu Rejected.'
    },
    rejectCountry: {
        CA:	'Canada',
        JP:	'Japan',
        US: 'USA',
        CH:	'Switzerland',
        PT:	'Portugal',
        BE:	'Belgium',
        MX:	'Mexico',
        BS:	'Brasil',
        HU:	'Hungary',
        DK:	'Denmark',
        NO:	'Norway',
        CZ:	'Czechia',
        SE:	'Sweden',
        PL:	'Poland',
        NL:	'Netherlands',
        ES:	'Spain',
        AT: 'Austria',
        IT:	'Italy',
        GB:	'United Kingdom',
        AU: 'Australia',
        KR: 'Korea',
        TW:	'Taiwan',
        ID:	'Indonesia',
        SG:	'Singapore',
        MY: 'Malaysia',
        TH:	'Thailand',
        HK:	'Hong Kong',
        DE:	'Germany',
        FR:	'France'
    },
    rejectLanguage: {
        en: 'English',
        de: 'German',
        pt: 'Portuguese',
        fr: 'French',
        es: 'Spanish',
        hu: 'Hungarian',
        da: 'Danish',
        nb: 'Norwegian',
        cs: 'Czech',
        sv: 'Swedish',
        pl: 'Polish',
        nl: 'Dutch',
        it: 'Italian'
    },
    rejectReason: {
        celebrity: 'Rejected due to non-compliant celebrity headline',
        Cryptocurrency: 'Rejected due to non-compliant Cryptocurrency headline',
        suffering: 'Rejected due to non-compliant suffering/violence headline',
        sensitive: 'Rejected due to non-compliant sensitive headline'
    }
};